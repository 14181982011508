<template>
  <b-col md="12" v-if="procedure" class="mt-2">
    <b-row v-if="hasError">
      <b-col md="12" class="mt-2">
        <div class="alert alert-danger">
          Une erreur est survenue si le problème persiste essayez de recharger ce contenu
          <b-badge class="bg-white text-primary" @click="reload" style="cursor: pointer" v-b-tooltip.hover.bottom title="Rafraîchir la page">RAFRAÎCHIR <font-awesome-icon icon="sync-alt"/></b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="6">
        <h4 class="text-center" v-if="estSignature">
          Historique
        </h4>
        <h4 class="text-center" v-else>
          Commentaires et historique
        </h4>
        <div class="card bg-light">
          <div class="card-body">
            <div class="card-text text-right mb-2" v-if="estOuverte && !estSignature">
              <form class="chat-form" data-children-count="2" @submit.prevent="sendMessage">
                <div class="text-left text-primary">Votre commentaire :</div>
                <b-textarea :rows="1" class="shadow p-2 mb-1" v-model="newMessage" :placeholder="`Contenu de votre commentaire - ce commentaire sera envoyé à tous les ${estSignature ? 'signataires' : 'validateurs'}.`" type="text"/>
                <b-btn type="submit" variant="primary" size="xs">
                    Envoyer ce commentaire
                </b-btn>
              </form>
            </div>
            <div class="card-text mt-1 mb-2" v-for="evenement in evenementsFiltered" :key="evenement.id">
              <div v-if="!estActivite(evenement)">
                <span class="text-muted" style="font-weight: 200; height: 100%;">{{ evenement.date | moment('DD/MM/YYYY') }} {{ evenement.date | moment('HH:mm')}} :</span>
                <span class="text-dark ml-1"
                  v-if="evenement.contact"
                >{{ evenement.contact.prenom }} {{ evenement.contact.nom }}</span>
                <span class="text-dark ml-1" v-else-if="evenement.user">{{ evenement.user.libelle }}</span>
                <span class="text-dark"> a commenté</span>
                <div class="shadow p-2 bg-white rounded text-dark">{{ evenement.description }}</div>
              </div>
              <div
                
                style="border-top: 1px solid black;border-bottom: 1px solid black;"
                class="pt-2 pb-2"
                v-else
              >
                <span class="text-muted" style="font-weight: 200; height: 100%;">{{ evenement.date | moment('DD/MM/YYYY') }} {{ evenement.date | moment('HH:mm')}} :</span>
                <span class="text-dark ml-1">{{ evenement.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <h4 class="text-center" v-if="estSignature">
              Liste des signataires
            </h4>
            <h4 class="text-center" v-else>
              Liste des validateurs
            </h4>
            <div class="card bg-light" v-for="contact in procedure.contacts" :key="contact.id">
              <div class="card-body">
                <h5 class="card-title">{{ contact.prenom }} {{ contact.nom }}</h5>
                <div class="card-text">
                  <p>
                    Courriel : {{ contact.email }}
                    <br>
                    Numéro de mobile : {{ contact.telephone }}
                    <br>
                    Rôles : {{ contact.roles.join(', ') }}
                    <br>
                  </p>
                  <div v-if="contact.url">
                    <p>
                      <i>Lien de redirection pour ce contact :</i>&nbsp;
                      <b-badge
                        size="sm"
                        variant="primary"
                        v-clipboard:copy="urlCode(contact)"
                        style="cursor:pointer;"
                      >Copier l'url</b-badge>
                    </p>



                    <p>
                      <i v-if="contact.relances.length" v-html="datesRelance(contact) + '<br/>'"></i>
                      <span class="text-danger" v-if="aRefuser(contact)">
                        Non <span v-if="estSignature">signé</span><span v-else>validé</span> <span v-if="contact.dateEtat">le {{ contact.dateEtat | moment('DD/MM/YYYY à HH:mm') }}</span>
                      </span>
                      <span
                        class="text-success"
                        v-else-if="aAccepter(contact)"
                      >
                        <span v-if="estSignature">Signé</span><span v-else>Validé</span> <span v-if="contact.dateEtat">le {{ contact.dateEtat | moment('DD/MM/YYYY à HH:mm') }}</span>
                      </span>
                      <span
                        class="text-dark"
                        v-else-if="estRefusee"
                      >
                      Procédure de <span v-if="estSignature">signature</span><span v-else>validation</span> interrompue suite à un ou plusieurs refus
                      </span>
                      <span
                        class="text-dark"
                        v-else-if="procedure.dateFin"
                      >
                        Procédure interrompue pour cause de d'annulation le {{ procedure.dateFin | moment('DD/MM/YYYY à HH:mm') }}
                      </span>
                      <span class="text-warning" v-else>
                        En cours de <span v-if="estSignature">signature</span><span v-else>validation</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <hr>
            <h4 class="text-center">Documents</h4>
          </b-col>
          <b-col md="12">
            <span v-if="estAcceptee && !estSignature">
              Visualiser le<span v-if="documentsSource.length > 1">s</span> document<span v-if="documentsSource.length > 1">s</span> validé<span v-if="documentsSource.length > 1">s</span> :<br/>
            </span>
            <span v-else>
              Visualiser le<span v-if="documentsSource.length > 1">s</span> document<span v-if="documentsSource.length > 1">s</span> à <span v-if="estSignature">signer</span><span v-else>valider</span> :<br/>
            </span>
            <div class="mt-2" v-for="document in documentsSource" :key="document.id">
              <a :href="document.url" target="_blank">
                <font-awesome-icon icon="file-pdf" size="lg" class="text-primary"/> {{ document.libelle }} ({{document.type}})
              </a>
            </div>
            <div v-if="documentsAutres.length">
              <hr style="border-top: dotted 1px;"/>
              Visualiser le<span v-if="documentsAutres.length > 1">s</span> preuve<span v-if="documentsAutres.length > 1">s</span> juridique de la <span v-if="estSignature">signature</span><span v-else>validation</span> :<br/>
              <div class="mt-2" v-for="document in documentsAutres" :key="document.id">
                <a :href="document.url" target="_blank">
                  <font-awesome-icon icon="file-pdf" size="lg" class="text-primary"/> {{ document.libelle }} ({{document.type}}) 
                </a>
                <span
                  v-b-tooltip.hover
                  :title="`Des données personnelles des ${estSignature?'signataires':'validateurs'} sont présentes dans ce(s) document(s). La transmission de ces données sans leurs accords peut être punie par la loi.`"
                >
                  <font-awesome-icon 
                    icon="info-circle" 
                    class="text-dark"
                  />
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import api from "@/services/api";
import { mapGetters } from "vuex";
import { SocketService } from '@/services/socket';

export default {
  name: "IframeProcedure",
  components: {},
  data() {
    return {
      evenements: [],
      newMessage: '',
      hasError: false
    };
  },
  computed: {
    ...mapGetters(["dossier"]),
    procedure() {
      return this.dossier.procedures.find(
        item => item.id === parseInt(this.$route.params.procedureId)
      );
    },
    estRefusee() {
      return this.procedure
        ? this.procedure.contacts.find(contact => this.aRefuser(contact))
        : false;
    },
    estAcceptee() {
      return this.procedure
        ? this.procedure.contacts.filter(contact => this.aAccepter(contact)).length === this.procedure.contacts.length
        : false;
    },
    estSignature() {
      return this.procedure
        ? this.procedure.type === api.PROCEDURE_TYPE_SIGNATURE
        : false;
    },
    estOuverte() {
      return this.procedure
        ? this.procedure.status === api.PROCEDURE_STATUT_OUVERTE
        : false;
    },
    documentsAutres() {
      return this.procedure
        ? this.procedure.documents.filter(document => [api.DOCUMENT_TYPE_PIECE_JOINTE, api.DOCUMENT_TYPE_PRINCIPAL].indexOf(document.type) === -1 )
        : [];
    },
    documentsSource() {
      return this.procedure
        ? this.procedure.documents.filter(document => [api.DOCUMENT_TYPE_PIECE_JOINTE, api.DOCUMENT_TYPE_PRINCIPAL].indexOf(document.type) !== -1 )
        : [];
    },
    evenementsFiltered() {
      return this.evenements.filter((evenement) => evenement.date > this.procedure.dateDebut);
    }
  },
  methods: {
    urlCode(contact){
      let routeData = this.$router.resolve({
        name: 'procedure_contact',
        params: {
          hashProcedure: this.procedure.hash,
          hashContact: contact.hash
        }
      });

      return process.env.VUE_APP_URL+routeData.href;
    },
    datesRelance: function(contact){
      return contact.relances.map(date => `Relance envoyée le ${this.$moment(date).format('DD/MM/YYYY à HH:mm')}`).join('<br/>')
    },
    aRefuser: function(contact) {
      return contact.etat === api.CONTACT_ETAT_REFUS;
    },
    aAccepter: function(contact) {
      return contact.etat === api.CONTACT_ETAT_ACCEPTATION;
    },
    estActivite: function(evenement) {
      return evenement.type === api.EVENEMENT_TYPE_ACTIVITE;
    },
    sendMessage:function(){
      this.hasError = false;
      api
        .postEvenement(this.dossier.id, this.procedure.id, {description: this.newMessage})
        .then((evenement) => {
          this.loadEvenements();
          this.newMessage = '';
        })
        .catch(() => this.hasError = true)
    },
    loadEvenements: function(){
      this.hasError = false;
      api
        .getEvenements(this.dossier.id, this.procedure.id)
        .then(evenements => {
          this.evenements = evenements.sort((a, b) => (a.date > b.date ? -1 : 1));
        })
        .catch(() => this.hasError = true)
      ;
    },
    reload: function(){
      window.location.reload();
    }
  },
  async beforeMount() {
    this.$store.commit('setProcedure', this.procedure);

    if(this.procedure.status === api.PROCEDURE_STATUT_BROUILLON){
      this.$router.push({name: 'iframe_procedure_modifier'})
    }

    this.loadEvenements();

    SocketService
      .getInstance("/procedure/" + this.procedure.hash)
      .addListener(
        SocketService.EVENT_EVENEMENT_NEW,
        (evenement) => {
            this.loadEvenements();
            parent.postMessage({
              name: 'evenement.NEW',
              value: evenement.id
            }, "*");
        },
        this
      );
      
    SocketService
      .getInstance("/procedure/" + this.procedure.hash)
      .addListener(
        SocketService.EVENT_PROCEDURE_UPDATE,
        function(newProcedure){
          const dossier = this.dossier;
          const procedure = dossier.procedures.find((item) => item.id === newProcedure.id)
          dossier.procedures = dossier.procedures.filter((item) => item.id !== newProcedure.id);
          dossier.procedures.push(newProcedure);

          this.$store.commit('setDossier', null);

          this.$store.commit('setDossier', dossier);
          if(procedure.status === api.PROCEDURE_STATUT_OUVERTE && newProcedure.status === api.PROCEDURE_STATUT_FERMEE){
            parent.postMessage({
              name: 'procedure.STOP',
              value: newProcedure.id
            }, "*");
          }
        },
        this
      );
  }
};
</script>