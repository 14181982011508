<template>
    <b-navbar toggleable="lg" type="dark" class="bg-dark" sticky>
        <b-navbar-brand :to="toLogo">
            Paraf <img src="https://yousign.com/_nuxt/img/logo-blanc-rvb.acecdc6.svg" style="height: 18px;">
            <span v-if="contact">
                | {{ contact.prenom }} {{ contact.nom }}
                <span v-if="procedure">
                    | {{ procedure.libelle }} | Procédure de {{ procedure.type }}
                </span>
                <small v-if="procedure.dateDebut">
                    - lancée le {{ procedure.dateDebut|moment('DD/MM/YYYY') }} à {{ procedure.dateDebut|moment('HH:mm') }}
                </small>
            </span>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
            <template #default="{ expanded }">
                <font-awesome-icon icon="bars" v-if="expanded || !countUnreadChatMessage"/>
                <span v-if="countUnreadChatMessage">
                    <b-badge variant="warning"  v-if="!expanded">
                        <font-awesome-icon icon="comments" />
                    </b-badge>
                </span>
            </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item href="#" @click="clickChat" right v-b-tooltip.hover.bottom title="Commentaires et remarques sur le document" v-if="procedure && estValidationProcedure && (estOuverteProcedure || estAccepteeProcedure || estRefuseeProcedure)">
                    <b-badge variant="warning" v-if="countUnreadChatMessage">
                        {{ countUnreadChatMessage }}
                        <font-awesome-icon icon="comments" />
                    </b-badge>
                    <font-awesome-icon icon="comments" :class="sidebarChat ? '' : getClassMuted()" v-else/>
                    <span class="d-sm-none">
                        Commentaires et remarques sur le document
                    </span>
                </b-nav-item>
                <b-nav-item href="#" v-if="role === 'gestionnaire' && isLoggedIn" @click=logOut right v-b-tooltip.hover.bottom title="Déconnexion de Paraf">
                    <font-awesome-icon icon="power-off" :class="getClassMuted()"/>
                    <span class="d-sm-none">
                        Déconnexion de Paraf
                    </span>
                </b-nav-item>
                <b-nav-item href="#" v-else-if="role === 'gestionnaire' && $route.name !=='login'" @click="logIn" right>
                    <font-awesome-icon icon="user" v-b-tooltip.hover.bottom text-align-left title="Si vous possèdez un compte Datacopro, identifiez-vous pour gagner du temps ! Si vous ne souhaitez pas vous connecter ou que vous ne possèdez pas de compte, Paraf fonctionne quand même !"/>
                    <span class="d-sm-none">
                        Connexion
                    </span>
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    import {mapGetters} from 'vuex'
    import api from '@/services/api'

    export default {
        name: 'Header',
        data(){
            return {
                contact: null
            }
        },
        methods: {
            logIn() {
                localStorage.setItem('redirect_login', window.location.href)
                this.$router.push({name: 'login'})
            },
            logOut() {
                this.$bvModal
                    .msgBoxConfirm(
                    "Êtes vous sur ?",
                    {
                        okTitle: "Oui",
                        size: "lg",
                        buttonSize: "lg",
                        okVariant: "primary",
                        cancelTitle: "Non, je souhaite rester connecté",
                    }
                    )
                    .then((value) => {
                        if(value){
                            this.$store.dispatch('logout')
                                .then(() => {
                                    this.$router.go()
                                })
                        }
                    });
            },
            getClassMuted(){
                return this.role == 'gestionnaire' ? 'text-light' : 'text-muted';
            },
            clickChat() {
                this.$store.dispatch('sidebarChat');
            },
            aRefuser: function(contact) {
                return contact.etat === api.CONTACT_ETAT_REFUS;
            },
            aAccepter: function(contact) {
                return contact.etat === api.CONTACT_ETAT_ACCEPTATION;
            }
        },
        computed: {
            toLogo(){
                if(this.role != 'gestionnaire'){
                    return this.procedure ? {name: 'procedure_contact'} : {};
                }else{
                    return this.dossier ? {name: 'dossier', params: {dossierId: this.dossier.id}} : { name: 'home'}
                }
                
                return {};
            },
            estOuverteProcedure(){
                return this.procedure && this.procedure.status == api.PROCEDURE_STATUT_OUVERTE;
            },
            estRefuseeProcedure() {
                return this.procedure
                    ? this.procedure.contacts.find(contact => this.aRefuser(contact))
                    : false;
            },
            estAccepteeProcedure() {
                return this.procedure
                    ? this.procedure.contacts.filter(contact => this.aAccepter(contact)).length === this.procedure.contacts.length
                    : false;
            },
            estValidationProcedure(){
                return this.procedure && this.procedure.type === api.PROCEDURE_TYPE_VALIDATION;
            },
            ...mapGetters([
                'countUnreadChatMessage',
                'sidebarChat',
                'isLoggedIn',
                'role',
                'dossier',
                'procedure'
            ])
        },
        watch: {
            procedure: function(newProcedure){
                if(this.$route.params.hashContact){
                    api
                        .getContactContact(this.$route.params.hashProcedure, this.$route.params.hashContact)
                        .then((contact) => {
                            this.contact = contact
                        });
                }else{
                    this.contact = null;
                }
            }
        }
    }
</script>

<style scoped>
.tooltip { opacity: 1 !important; }
</style>