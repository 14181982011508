import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './services/router';

Vue.config.productionTip = false;

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/theme.css'
import '../src/assets/css/custom.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPowerOff, faInfoCircle, faBars, faCaretSquareDown, faCaretDown, faTrash, faSignInAlt, faUpload, faAngleDown, faExclamationTriangle, faExclamationCircle, faAngleUp, faEllipsisV, faUser, faUserFriends, faFilter, faTimes, faPlay, faStop, faUnlink, faRedo, faPause, faPlus, faBell, faComment, faUsers, faCheck, faDesktop, faBan, faUserCheck, faKey, faQuestionCircle, faComments, faEdit, faCaretLeft, faSignOutAlt, faAddressCard, faCog, faSyncAlt, faArrowRight, faLock, faCheckCircle, faCircle, faSmile, faMeh, faFrown, faFile, faFilePdf, faFileAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faBars);
library.add(faSignOutAlt);
library.add(faCaretLeft);
library.add(faKey);
library.add(faQuestionCircle);
library.add(faPowerOff);
library.add(faUser);
library.add(faEllipsisV);
library.add(faUser);
library.add(faUserFriends);
library.add(faFilter);
library.add(faTimes);
library.add(faPlay);
library.add(faStop);
library.add(faUnlink);
library.add(faRedo);
library.add(faPause);
library.add(faPlus);
library.add(faBell);
library.add(faComment);
library.add(faUsers);
library.add(faCheck);
library.add(faDesktop);
library.add(faBan);
library.add(faUserCheck);
library.add(faComments);
library.add(faEdit);
library.add(faAddressCard);
library.add(faCog);
library.add(faSyncAlt);
library.add(faArrowRight);
library.add(faLock);
library.add(faCheckCircle);
library.add(faCircle);
library.add(faAngleDown);
library.add(faAngleUp);
library.add(faExclamationTriangle);
library.add(faExclamationCircle);
library.add(faSmile);
library.add(faMeh);
library.add(faFrown);
library.add(faFile);
library.add(faSignOutAlt);
library.add(faFilePdf);
library.add(faUpload);
library.add(faInfoCircle);
library.add(faTrash);
library.add(faCaretDown);

const VuePageTitle = require('vue-page-title');
Vue.use(VuePageTitle, {
  router,
  suffix: '- Paraf'
})

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { required, email } from 'vee-validate/dist/rules';
extend('email', {
  ...email,
  message: 'Le format email n\'est pas respecté'
});
extend('required', {
  ...required,
  message: 'Ce champs est obligatoire'
});

const moment = require('moment')
require('moment/locale/fr')
Vue.use(require('vue-moment'), {
    moment
})

import VueTelInput from 'vue-tel-input';
Vue.use(VueTelInput);
import 'vue-tel-input/dist/vue-tel-input.css';

const VueSelect = require('vue-select');
Vue.component('v-select', VueSelect.VueSelect)

import "vue-select/dist/vue-select.css";

import store from './services/store'

export const bus = new Vue();
new Vue
({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
