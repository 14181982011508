<template>
  <b-col md="12" v-if="procedure" class="mt-2">
    <b-row class="mt-2">
      <b-col md="6">
        <h4 class="text-center" v-if="estSignature">
          <font-awesome-icon icon="plus" /> Ajouter un signataire
        </h4>
        <h4 class="text-center" v-else>
          <font-awesome-icon icon="plus" /> Ajouter un validateur
        </h4>
        <div class="card bg-light shadow mb-2" v-if="dossier.datacopro">
          <b-btn-group class="text-center w-100">
            <b-btn :variant="showedFormContactCopropriete ? 'primary' :'light'" class="w-50" @click="showFormContactCopropriete">
              Contacts de la copropriété<br/>
              <font-awesome-icon icon="caret-down" class="fa-2x"/>
            </b-btn>
            <b-btn :variant="showedFormContact && !showedFormContactCopropriete ? 'primary' :'light'" class="w-50" style="border-left: 1px solid black;" @click="showFormContact">
              Contacts externes<br/>
              <font-awesome-icon icon="caret-down" class="fa-2x"/>
            </b-btn>
          </b-btn-group>
        </div>
        <div v-if="showedFormContactCopropriete" class="mb-2">
          <v-select 
            v-if="!loadingContactsCopropriete"
            v-model="contactCopropriete" 
            label="libelle"
            :options="contactCoproprieteOptions" 
            id="contactCopropriete-input"
            placeholder="Sélectionnez un contact"
            style="border-radius: 0;"
          ></v-select>
          <Loading v-else :size=3 />
        </div>
        <div class="card bg-white shadow" v-if="showedFormContact">
          <div class="card-body">
            <div class="card-text">
              <b-form ref="form" @submit.stop.prevent="handleSubmitContact">
                <b-form-group
                  :state="prenomState"
                  label="Prénom"
                  label-for="prenom-input"
                  invalid-feedback="Le prénom est obligatoire et ne doit pas comporter de caractères spéciaux"
                  label-class="text-primary"
                >
                  <b-form-input
                    id="prenom-input"
                    v-model="prenom"
                    :state="prenomState"
                    placeholder="Prénom"
                    class="shadow"
                    required
                    style="border-radius: 0;"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :state="nomState"
                  label="Nom"
                  label-for="nom-input"
                  invalid-feedback="Le nom est obligatoire et ne doit pas comporter de caractères spéciaux"
                  label-class="text-primary"
                >
                  <b-form-input
                    id="nom-input"
                    v-model="nom"
                    :state="nomState"
                    placeholder="Nom"
                    class="shadow"
                    required
                    style="border-radius: 0;"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :state="emailState"
                  label="Courriel"
                  label-for="email-input"
                  :invalid-feedback="invalidFeedbackEmail"
                  label-class="text-primary"
                >
                  <b-form-input
                    id="email-input"
                    v-model="email"
                    :state="emailState"
                    placeholder="Courriel"
                    class="shadow"
                    required
                    style="border-radius: 0;"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :state="telephoneState"
                  label="Numéro de mobile"
                  label-for="telephone-input"
                  :invalid-feedback="invalidFeedbackTelephone"
                  label-class="text-primary"
                >
                  <vue-tel-input v-model="telephone" defaultCountry="FR" :preferredCountries="['FR', 'BE']" mode="international" :inputOptions="{placeholder: 'Numéro de mobile', showDialCode: false}" class="form-control shadow" style="border: 1px solid #dee2e6; display: flex;">
                    <template slot="arrow-icon">
                      <font-awesome-icon icon="caret-down"/>
                    </template>
                  </vue-tel-input>
                </b-form-group>

                <b-form-group
                  :state="rolesState"
                  label="Rôle"
                  label-for="role-select"
                  invalid-feedback="Le rôle est obligatoire"
                  label-class="text-primary"
                  v-if="rolesOptions.length"
                >
                  <v-select multiple v-model="roles" :options="rolesOptions" class="shadow"/>
                </b-form-group>

                <b-btn type="submit" variant="primary" size="sm" class="float-right">Ajouter ce <span v-if="estSignature">signataire</span><span v-else>validateur</span></b-btn>
              </b-form>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="6" style="border-left: 1px solid #dee2e6">
        <b-row>
          <b-col md="12">
            <h4 class="text-center" v-if="estSignature">
              <font-awesome-icon icon="users" /> Liste des signataires
            </h4>
            <h4 class="text-center" v-else>
              <font-awesome-icon icon="users" /> Liste des validateurs
            </h4>
            <div class="card bg-light" v-for="contact in procedure.contacts" :key="contact.id">
              <div class="card-body">
                <a href="#" @click="supprimerContact(contact)" class="text-dark m-2">
                  <font-awesome-icon size="sm" icon="trash" class="float-right"/>
                </a>
                <h5 class="card-title" @click="contactEdited = contact" v-if="!contactEdited || contactEdited.id !== contact.id" style="cursor: pointer;">
                  {{ contact.prenom }} {{ contact.nom }}
                </h5>
                <div class="card-text">
                  <p @click="contactEdited = contact" v-if="!contactEdited || contactEdited.id !== contact.id" style="cursor: pointer;">
                      Courriel : {{ contact.email }}<br>
                      Numéro de mobile : {{ contact.telephone }}<br>
                      <span v-if="contact.roles.length">
                        Rôles : {{ contact.roles.join(', ') }}
                        <br>
                      </span>
                  </p>
                  <div v-else>
                    <b-input-group
                      prepend="Prénom"
                      size="sm"
                      class="mt-1"
                    >
                      <b-form-input v-model="contactEditedPrenom" :class="`${contactEditedPrenomError ? 'is-invalid' : ''}`"/>
                    </b-input-group>

                    <b-input-group
                      prepend="Nom"
                      size="sm"
                      class="mt-1"
                    >
                      <b-form-input v-model="contactEditedNom" :class="`${contactEditedNomError ? 'is-invalid' : ''}`" />
                    </b-input-group>

                    <b-input-group
                      prepend="Courriel"
                      size="sm"
                      class="mt-1"
                    >
                      <b-form-input v-model="contactEditedEmail" :class="`${contactEditedEmailError ? 'is-invalid' : ''}`" />
                    </b-input-group>

                    <b-input-group
                      prepend="Numéro de mobile"
                      size="sm"
                      class="mt-1"
                    >
                      <vue-tel-input v-model="contactEditedTelephone" defaultCountry="FR" :preferredCountries="['FR', 'BE']" mode="international" :inputOptions="{placeholder: 'Numéro de mobile', showDialCode: true}"  :class="`form-control ${contactEditedEmailTelephone ? 'is-invalid' : ''}`" style="border: 1px solid #dee2e6;display: flex;">
                        <template slot="arrow-icon">
                          <font-awesome-icon icon="caret-down"/>
                        </template>
                      </vue-tel-input>
                    </b-input-group>

                    <b-input-group
                      prepend="Roles"
                      size="sm"
                      class="mt-1"
                      v-if="rolesOptions.length"
                    >
                      <v-select multiple v-model="contactEditedRoles" :options="rolesOptions" :class="`${contactEditedRolesError ? 'is-invalid' : ''}`" style="flex: 1 1 auto;background: white"/>
                    </b-input-group>

                    <b-btn type="submit" variant="primary" size="sm" class="float-right mt-2" @click="handleSubmitContactEdited" :disabled="!validContactEdited">Modifier</b-btn>
                    <b-btn type="submit" variant="danger" size="sm" class="float-right mt-2 mr-1" @click="contactEdited = null">Annuler</b-btn>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="text-center lead">
            <div v-if="procedure.contacts.length === 0" style="padding: 15px 30px 15px 30px;" class="text-muted ">
              <font-awesome-icon icon="users" class="fa-3x float-left ml-4"/>
              <font-awesome-icon icon="users" class="fa-3x float-right mr-4"/>
              <div class="clearfix"></div>
              <font-awesome-icon icon="users" class="fa-5x"/>
              <div class="clearfix"></div>
              <font-awesome-icon icon="users" class="fa-3x float-left ml-4"/>
              <font-awesome-icon icon="users" class="fa-3x float-right mr-4"/>
              <div class="clearfix"></div>
            </div>
            <br/>
            <span v-if="procedure.contacts.length > 1">Vous avez sélectionné {{ procedure.contacts.length }} contacts.</span>
            <span v-else-if="procedure.contacts.length == 1">Vous avez sélectionné {{ procedure.contacts.length }} contact.</span>
            <span v-else>Aucun contact a été sélectionné.</span>
            <br/>
            <br/>
            <i :class="procedure.contacts.find(item => item.email.toUpperCase() === user.email.toUpperCase()) ? '' : 'font-weight-bold text-warning'">
              P.S. : N'oubliez pas d’ajouter vos coordonnées si vous devez aussi <span v-if="estSignature">signer</span><span v-else>valider</span> 
              <span v-if="procedure.contacts.length > 1"> les documents.</span>
              <span v-else> le document.</span> 
              &nbsp;
              <font-awesome-icon
                id="tooltip-documents"
                icon="info-circle"
              />
              <b-tooltip target="tooltip-documents" triggers="hover">
                Liste des documents :<br/>
                <span v-for="document in procedure.documents" :key="document.id">
                  - {{ document.libelle }}<br/>
                </span>
              </b-tooltip>
            </i>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2" v-if="procedure.contacts.length !== 0 && procedure.documents.length !== 0">
        <hr/>
        <u>Vous êtes sur le point d'envoyer une demande de <span v-if="estSignature">signature</span><span v-else>validation</span> à {{ procedure.contacts.length }}
          <span v-if="procedure.contacts.length > 1">contacts</span>
          <span v-else>contact</span> 
          (cf liste ci-dessus).
        </u>
        
        <p class="mt-3">
          Ils recevront un email de la part de <strong>Yousign</strong> et devront cliquer sur le lien pour parcourir et <span v-if="estSignature">signer</span><span v-else>valider</span> le document. 
        </p>

        <div v-if="estSignature">
          <p>
            Vous recevrez, en tant que responsable de la procédure de signature, un email une fois que tout le monde aura signé OU en cas de refus de signature, sur l'email : {{ user.email }}
          </p>

          <div class="ml-6">
            <font-awesome-icon icon="exclamation-triangle" class="text-dark fa-2x float-left mr-2 mt-2"/>

            Attention, une fois la procédure envoyée, vous ne pourrez plus modifier les coordonnées des signataires. 
            <br/>
            Lors de la signature du document un sms ou email avec un code personnalisé sera envoyé à chacun d’entre eux.
          </div>

          <p class="mt-3">
            Chaque signature vous sera facturée 2.4€HT/signature. Si vous envoyez une procédure de signature et qu’un signature la refuse mais que d’autres l’ont déjà signée, alors vous serez tout de même facturé sur les signatures obtenues.
            Par conséquent, pensez à toujours passer par la procédure de validation avant la procédure de signature. Il s'agit d'une étape de contrôle du PV qui peut être réalisée autant de fois que vous le souhaitez sans facturation supplémentaire.
          </p>
        </div>
        <div v-else>
          <p>
            En tant que responsable de la validation vous recevrez un email sur {{ user.email }} :
          </p>

          <ul>
            <li> lors de chaque commentaire sur la procédure de validation</li>
            <li>une fois que tout le monde aura validé OU en cas de refus de validation</li>
          </ul>

          <div class="ml-6">
            <font-awesome-icon icon="exclamation-triangle" class="text-dark fa-2x float-left mr-2"/>

            Attention, une fois la procédure envoyée, vous ne pourrez plus modifier les coordonnées des validateurs.
          </div>
        </div>
      </b-col>
      <b-col md="12" class="mt-2" v-if="failed">
        <div class="alert alert-danger">
          <span v-if="estErrorPhoneYousign">
            Un numéro de téléphone semble invalide
          </span>
          <span v-else>
            Une erreur est survenue si le problème persiste essayez de recharger ce contenu
            <b-badge class="bg-white text-primary" @click="reload" style="cursor: pointer" v-b-tooltip.hover.bottom title="Rafraîchir la page">RAFRAÎCHIR <font-awesome-icon icon="sync-alt"/></b-badge>
          </span>
        </div>
      </b-col>
      <b-col md="12" class="mt-4 mb-2">
        <b-btn 
          @click="commencerProcedure" 
          variant="primary" 
          class="float-right" 
          :disabled="procedure.contacts.length === 0 || procedure.documents.length === 0 || pushing"
          v-if="!estSignature"
        >
          <b-spinner small type="grow" v-if="pushing"></b-spinner>
           Envoyer la procédure de validation
        </b-btn>
        <b-btn 
          @click="clickSuivant"
          variant="primary" 
          class="float-right" 
          :disabled="procedure.contacts.length === 0 || procedure.documents.length === 0"
          v-else
        >
          Suivant
        </b-btn>

        <b-btn 
          @click="supprimerProcedure" 
          variant="danger" 
          class="float-right mr-2" 
          :disabled="pushing"
        >
          Annuler
        </b-btn>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import api from "@/services/api";
import { mapGetters } from "vuex";
import { SocketService } from '@/services/socket';
import Loading from '@/views/components/Loading'
import { estUnNominatifValide } from '@/helpers/Validation'


export default {
  name: "IframeProcedureModifier",
  components: {
    Loading
  },
  data() {
    return {
      showedFormContact: false,
      showedFormContactCopropriete: false,

      failed: false,
      pushing: false,

      prenom: null,
      prenomState: null,

      nom: null,
      nomState: null,

      email: null,
      emailState: null,

      telephone: '',
      telephoneState: null,

      loadingContactsCopropriete: false,
      contactCopropriete: null,
      contactCoproprieteOptions: [],
      
      roles: null,
      rolesState: null,

      contactEdited: null,
      contactEditedEmail: null,
      contactEditedEmailError: false,
      contactEditedTelephone: null,
      contactEditedTelephoneError: false,
      contactEditedPrenom: null,
      contactEditedPrenomError: null,
      contactEditedNom: null,
      contactEditedNomError: null,
      contactEditedRoles: null,
      contactEditedRolesError: null,
      estErrorPhoneYousign: false
    }
  },
  computed: {
    ...mapGetters(["dossier", "user"]),
    procedure() {
      return this.dossier.procedures.find(
        item => item.id === parseInt(this.$route.params.procedureId)
      );
    },
    estSignature() {
      return this.procedure
        ? this.procedure.type === api.PROCEDURE_TYPE_SIGNATURE
        : false;
    },
    rolesOptions(){
      return this.procedure.roles;
    },
    invalidFeedbackTelephone(){
      if(this.telephone && this.validTelephone(this.telephone)){
        return 'Un contact est déjà présent dans la liste des validateurs avec ces coordonnées';
      }
      return 'Le format du numéro de mobile n\'est pas respecté'
    },
    invalidFeedbackEmail(){
      if(this.email && this.validEmail(this.email)){
        return 'Un contact est déjà présent dans la liste des validateurs avec ces coordonnées';
      }
      return 'Le format de l\'email n\'est pas respecté'
    },
    validContactEdited: function() {
      let valid = true;
      this.contactEditedPrenomError = false;
      this.contactEditedNomError = false;
      this.contactEditedTelephoneError = false;
      this.contactEditedRolesError = false;
      this.contactEditedEmailError = false;

      if(!this.contactEditedPrenom || this.contactEditedPrenom.trim() == '' || !this.validPrenom(this.contactEditedPrenom)){
        this.contactEditedPrenomError = true;
        valid = false;
      }
      if(!this.contactEditedNom || this.contactEditedNom.trim() == '' || !this.validNom(this.contactEditedNom)){
        this.contactEditedNomError = true;
        valid = false;
      }
      if(!this.contactEditedTelephone || !this.validTelephone(this.contactEditedTelephone)){
        this.contactEditedTelephoneError = true;
        valid = false;
      }
      if(this.rolesOptions.length && (!this.contactEditedRoles || this.contactEditedRoles.length == 0)){
        this.contactEditedRolesError = true;
        valid = false;
      }
      if(!this.contactEditedEmail || this.contactEditedEmail.trim() == '' || !this.validEmail(this.contactEditedEmail)){
        this.contactEditedEmailError = true;
        valid = false;
      }

      const contactSimilaire = this.procedure.contacts.find((contact) => contact.email == this.email && contact.telephone == this.telephone && contact.id !== this.contactEdited.id);
      if(contactSimilaire){
        this.contactEditedEmailError = true;
        this.contactEditedTelephone = true;
        valid = false;
      }

      return valid;
    },
  },
  methods: {
    reload: function(){
      window.location.reload();
    },
    clickSuivant: function(){
      if((this.email && this.email != '') || (this.nom && this.nom != '') || (this.prenom && this.prenom != '') || (this.telephone && this.telephone != '') ){
        this.$bvModal.msgBoxOk("Vous n'avez pas terminé l'ajout d'un contact.");
      }else{
        this.$router.push({ name: 'iframe_procedure_modifier_signatures'});
      }
    },
    commencerProcedure: function(){
      if((this.email && this.email != '') || (this.nom && this.nom != '') || (this.prenom && this.prenom != '') || (this.telephone && this.telephone != '') ){
        this.$bvModal.msgBoxOk("Vous n'avez pas terminé l'ajout d'un contact.");
      }else{
        this.pushing = true;
        this.failed = false;
        this.estErrorPhoneYousign = false;
        api
          .commencerProcedure(this.dossier.id, this.procedure.id)
          .then(() => {
            this.$router.push({ name: 'iframe_procedure'})
            parent.postMessage({
              name: 'procedure.START',
              value: this.procedure.id
            }, "*");
          })
          .catch((error) => {
            if(error.response && error.response.data.message && error.response.data.message.search(/phone/) !== -1){
              this.estErrorPhoneYousign = true;
            }
            this.failed = true;
          })
          .finally(() => {
            this.pushing = false;
          })
      }
    },
    supprimerProcedure: function(){
      this.pushing = true;
      this.failed = false;
      api
        .deleteProcedure(this.dossier.id, this.procedure.id)
        .then(() => {
          const procedureId = this.procedure.id;
          this.dossier.procedures = this.dossier.procedures.filter(procedure => procedure.id !== this.procedure.id)
          parent.postMessage({
            name: 'procedure.DELETE',
            value: procedureId
          }, "*");
        })
        .catch((error) => {
          this.failed = true;
        })
        .finally(() => {
          this.pushing = false;
        })
    },
    resetFormContact() {
      this.prenom = null;
      this.nom = null;
      this.email = null;
      this.telephone = '';
      this.roles = [];

      this.prenomState = null;
      this.nomState = null;
      this.emailState = null;
      this.telephoneState = null;
      this.rolesState = null;

      this.failed = false;
      this.pushing = false;
    },
    checkFormContactValidity() {
      let valid = this.$refs.form.checkValidity()
      if(!this.prenom || this.prenom.trim() == '' || !this.validPrenom(this.prenom)){
        this.prenomState = false;
        valid = false;
      }
      if(!this.nom || this.nom.trim() == '' || !this.validNom(this.nom)){
        this.nomState = false;
        valid = false;
      }
      if(!this.telephone || !this.validTelephone(this.telephone)){
        this.telephoneState = false;
        valid = false;
      }
      if(this.rolesOptions.length && (!this.roles || this.roles.length == 0)){
        this.rolesState = false;
        valid = false;
      }
      if(!this.email || this.email.trim() == '' || !this.validEmail(this.email)){
        this.emailState = false;
        valid = false;
      }

      const contactSimilaire = this.procedure.contacts.find((contact) => contact.email == this.email && contact.telephone == this.telephone);
      if(contactSimilaire){
        this.telephoneState = false;
        this.emailState = false;
        valid = false;
      }
      return valid
    },
    handleSubmitContactEdited: function() {
      this.failed = false;
      api
        .putContact(this.dossier.id, this.procedure.id, this.contactEdited.id, {
          prenom: this.contactEditedPrenom,
          nom: this.contactEditedNom,
          email: this.contactEditedEmail,
          telephone: this.contactEditedTelephone,
          roles: this.contactEditedRoles,
        })
        .then(() => {
          this.contactEdited = null;
        })
        .catch((error) => {
          this.failed = true;
        })
    },
    supprimerContact: function(contact) {
      this.failed = false;
      api
        .deleteContact(this.dossier.id, this.procedure.id, contact.id)
        .catch((error) => {
          this.failed = true;
        })
    },
    handleSubmitContact() {
      if (!this.checkFormContactValidity()) {
        return
      }
      this.failed = false;
      this.pushing = true;
      let promise = null;
      let routeDataEmail = this.$router.resolve({
        name: 'procedure_contact',
        params: {
          hashProcedure: ':hashProcedure',
          hashContact: ':hashContact'
        }
      });
      if(this.contact){
        promise = api.putContact(this.dossier.id, this.procedure.id, this.contact.id, {
          prenom: this.prenom,
          nom: this.nom,
          email: this.email,
          telephone: this.telephone,
          roles: this.roles,
        });
      }else{
        promise = api.postContact(this.dossier.id, this.procedure.id, {
          prenom: this.prenom,
          nom: this.nom,
          email: this.email,
          telephone: this.telephone,
          roles: this.roles,
          urlEmail: process.env.VUE_APP_URL+routeDataEmail.href
        });
      }
      promise
        .then((contact) => {
          this.resetFormContact();
        })
        .catch((error) => {
          this.failed = true;
        })
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPrenom: function (prenom) {
      return estUnNominatifValide(prenom)
    },
    validNom: function (nom) {
      return estUnNominatifValide(nom);
    },
    validTelephone: function (phoneNumber) {
      let regEx = /^\+[1-9]\d{10,14}$/;
      if(!regEx.test(phoneNumber.replace(/ /g, ''))){
        return false;
      }
      //france
      regEx = /^\+33\d{10}/;
      if(regEx.test(phoneNumber.replace(/ /g, ''))){
        return false;
      }
      return true;
    },
    showFormContact(){
      this.showedFormContact = true;
      this.showedFormContactCopropriete = false;
    },
    showFormContactCopropriete(){
      this.failed = false;

      this.showedFormContact = false;
      this.showedFormContactCopropriete = true;
      this.contactCopropriete = null;

      this.contactCoproprieteOptions = [];
      this.loadingContactsCopropriete = true;
      api.getContactsDatacopro(this.dossier.id)
        .then((contacts) => {
          contacts.forEach((contactLoop) => {
            let libelle = '';
            if(contactLoop.prenom){
              libelle += contactLoop.prenom;
            }
            if(contactLoop.nom){
              libelle += ' ' + contactLoop.nom;
            }
            if(contactLoop.mail){
              libelle += ' [' + contactLoop.mail + ']';
            }

            let mobile = contactLoop.mobile;
            if(contactLoop.tel && contactLoop.tel.indexOf('+336') === 0){
              mobile = contactLoop.tel;
            }
            this.contactCoproprieteOptions.push({
              id: contactLoop.id,
              prenom: contactLoop.prenom,
              nom: contactLoop.nom,
              email: contactLoop.mail,
              mobile: mobile,
              libelle: libelle.trim()
            })
          })
        })
        .catch(() => this.failed = true)
        .finally(() => this.loadingContactsCopropriete = false)
    }
  },
  watch: {
    contactCopropriete(value){
      if(value){
        this.email = value.email;
        this.prenom = value.prenom;
        this.nom = value.nom;
        this.telephone = value.mobile;
        this.showedFormContact = true;
        this.contactCopropriete = null;
      }
    },
    prenom(value){
      if(value && !this.validPrenom(value) ){
        this.prenomState = false;
      }else{
        this.prenomState = true;
      }
    },
    nom(value){
      if(value && !this.validNom(value) ){
        this.nomState = false;
      }else{
        this.nomState = true;
      }
    },
    email(value){
      if(value && !this.validEmail(value) ){
        this.emailState = false;
      }else{
        this.emailState = true;
      }
    },
    telephone(value){
      if(value && value.length > 4 && !this.validTelephone(value) ){
        this.telephoneState = false;
      }else{
        this.telephoneState = true;
      }
    },
    contactEdited(value){
      if(value){
        this.contactEditedEmail = value.email;
        this.contactEditedEmailError = false;
        this.contactEditedTelephone = value.telephone;
        this.contactEditedTelephoneError = false;
        this.contactEditedPrenom = value.prenom;
        this.contactEditedPrenomError = false;
        this.contactEditedNom = value.nom;
        this.contactEditedNomError = false;
        this.contactEditedRoles = value.roles;
        this.contactEditedRolesError = false;
      }
    }
  },
  
  async beforeMount() {
    this.$store.commit('setProcedure', this.procedure);
    
    if(this.procedure.status !== api.PROCEDURE_STATUT_BROUILLON){
      this.$router.push({name: 'iframe_procedure'})
    }

    if(!this.dossier.datacopro){
      this.showedFormContact = true;
    }

    SocketService
      .getInstance("/procedure/" + this.procedure.hash)
      .addListener(
        SocketService.EVENT_PROCEDURE_UPDATE,
        function(newProcedure){
          const dossier = this.dossier;
          dossier.procedures = dossier.procedures.filter((item) => item.id !== newProcedure.id);
          dossier.procedures.push(newProcedure);

          this.$store.commit('setDossier', null);
          this.$store.commit('setDossier', dossier);
        },
        this
      );
  }
};
</script>