<template>
  <b-modal id="modal-modifier-procedure" 
      :visible="show" 
      @hide="onHide"
      
      @show="showModal"
      @hidden="resetModal"
      :ok-title="procedure ? 'Modifier' : 'Ajouter'"
      cancel-title="Annuler"
      :ok-disabled="pushing"
      no-close-on-backdrop
    >
    <template #modal-header>
      <h5 class="modal-title">
        {{ procedure ? `Modifier la procédure ${procedure.libelle}` : 'Ajouter une procédure' }}
      </h5>
    </template>
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="libelleState"
        label="Libellé*"
        label-for="libelle-input"
        required
        invalid-feedback="Le libellé est obligatoire"
      >
        <b-form-input
          id="libelle-input"
          v-model="libelle"
          :state="libelleState"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="typeState"
        label="Type*"
        label-for="type-input"
        required
        invalid-feedback="Le type doit faire partie de la liste"
      >
        <b-form-select 
          v-model="type" 
          :options="typeOptions" 
          :state="typeState"
          value-field="value"
          text-field="text"
          id="type-input"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        :state="rolesState"
        label="Rôles proposés (séparé par une ,)"
        label-for="roles-input"
      >
        <b-form-input
          id="roles-input"
          v-model="roles"
          :state="rolesState"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="urlEmailGestionnaireState"
        label="Url emails du gestionnaire"
        label-for="urlEmailGestionnaire-input"
      >
        <b-form-input
          id="urlEmailGestionnaire-input"
          v-model="urlEmailGestionnaire"
          :state="urlEmailGestionnaireState"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="suiveursState"
        label="Emails suiveurs (séparé par une ,)"
        label-for="suiveurs-input"
      >
        <b-form-input
          id="suiveurs-input"
          v-model="suiveurs"
          :state="suiveursState"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="urlEmailSuiveursState"
        label="Url emails des suiveurs"
        label-for="urlEmailSuiveurs-input"
      >
        <b-form-input
          id="urlEmailSuiveurs-input"
          v-model="urlEmailSuiveurs"
          :state="urlEmailSuiveursState"
        ></b-form-input>
      </b-form-group>
    </b-form>
    <Loading v-if="pushing" :size=3 />
    <b-alert variant="danger" :show="failed">
      Une erreur s'est produite lors de la mise à jour
    </b-alert>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api'
import Loading from '@/views/components/Loading'
import { SocketService } from '@/services/socket';
import { bus } from '@/main';

export default {
  name: 'ModalProcedureModifier',
  components: {
    Loading
  },
  props: ['procedure', 'show'],
  data() {
    return {
      failed: false,
      pushing: false,

      libelle: null,
      libelleState: null,
      
      type: null,
      typeState: null,
      typeOptions: [
        {
          text: 'Demande de signature',
          value: api.PROCEDURE_TYPE_SIGNATURE,
        },
        {
          text: 'Demande de validation',
          value: api.PROCEDURE_TYPE_VALIDATION,
        }
      ],

      urlEmailGestionnaire: null,
      urlEmailGestionnaireState: null,

      suiveurs: null,
      suiveursState: null,

      urlEmailSuiveurs: null,
      urlEmailSuiveursState: null,

      roles: null,
      rolesState: null,
    }
  },
  computed: {
    ...mapGetters([
        'dossier'
    ]),
  },
  methods: {
    showModal() {
      this.resetModal();
      if(this.procedure){
        this.libelle = this.procedure.libelle;
        this.type = this.procedure.type;
        this.urlEmailGestionnaire = this.procedure.urlEmailGestionnaire;
        this.urlEmailSuiveurs = this.procedure.urlEmailSuiveurs;
        this.suiveurs = this.procedure.suiveurs.join(', ');
        this.roles = this.procedure.roles.join(', ');
      }else{
        let routeDataEmail = this.$router.resolve({
          name: 'dossier_procedure',
          params: {
            dossierId: ':dossierId',
            procedureId: ':procedureId',
          }
        });
        this.type = api.PROCEDURE_TYPE_VALIDATION;
        this.urlEmailGestionnaire = process.env.VUE_APP_URL+routeDataEmail.href;
      }
    },
    resetModal() {
      
      this.libelle = null;
      this.type = null;
      this.suiveurs = null;
      this.roles = null;
      this.urlEmailGestionnaire = null;
      this.urlEmailSuiveurs = null;

      this.libelleState = null;
      this.typeState = null;
      this.suiveursState = null;
      this.rolesState = null;
      this.urlEmailGestionnaireState = null;
      this.urlEmailSuiveursState = null;

      this.failed = false;
      this.pushing = false;
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity()
      if(!this.libelle  || this.libelle.trim() == ''){
        this.libelleState = false;
        valid = false;
      }
      return valid
    },
    onHide(bvModalEvt) {
      if(bvModalEvt.trigger != 'ok'){
        this.$emit('hide');
      }else{
        this.handleOk(bvModalEvt);
      }
    },
    handleOk(bvModalEvt) {
      //pas de fermeture
      bvModalEvt.preventDefault()
      //submit le formulaire
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.pushing = true;
      let promise = null;
      if(this.procedure){
        promise = api.putProcedure(this.dossier.id, this.procedure.id, {
          libelle: this.libelle,
          roles: this.roles ? this.roles.replace(/ /g, '').split(',') : [],
          type: this.type,
          urlEmailGestionnaire: this.urlEmailGestionnaire,
          suiveurs: this.suiveurs ? this.suiveurs.replace(/ /g, '').split(',') : [],
          urlEmailSuiveurs: this.urlEmailSuiveurs ? this.urlEmailSuiveurs : '',
        });
      }else{
        promise = api.postProcedure(this.dossier.id, {
          libelle: this.libelle,
          type: this.type,
          roles: this.roles ? this.roles.replace(/ /g, '').split(',') : [],
          urlEmailGestionnaire: this.urlEmailGestionnaire,
          urlEmailGestionnaire: this.urlEmailGestionnaire,
          suiveurs: this.suiveurs ? this.suiveurs.replace(/ /g, '').split(',') : [],
          urlEmailSuiveurs: this.urlEmailSuiveurs ? this.urlEmailSuiveurs : '',
        })
        .then((procedure) => {
          bus.$emit('procedure.NEW', procedure);
          return procedure;
        })
        ;
      }
      promise
        .then(procedure => {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-modifier-procedure');
            this.$emit('hide');
          });
        })
        .catch((error) => {
          this.failed = true;
        })
        .finally(() => {
          this.pushing = false;
        })
    }
  },
}
</script>