<template>
  <LayoutBase :fullscreen="true">
    <b-col md=12>
      <div class="page-header">
      </div>
      <TableDossiers/>
    </b-col>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/views/layouts/LayoutBase'
import TableDossiers from '@/views/components/Table/TableDossiers'
import api from '@/services/api'
import store from '@/services/store';

export default {
  name: 'Dossiers',
  components: {
    LayoutBase,
    TableDossiers
  },
  data() {
      this.$store.commit('setDossier', null);
    return {
    }
  },
  methods: {
    
  }
}
</script>