<template>
    <div class="sidebar" v-if="sidebarChat">
        <div class="sidebar-content">
            <div class="chat-module" data-filter-list="chat-module-body" style="min-height: 500px;">
                <div class="chat-module-top">
                    <form v-on:submit.prevent>
                        <div class="input-group input-group-round" data-children-count="1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" data-children-count="0">
                                    <i class="material-icons">search</i>
                                </span>
                            </div>
                            <input type="search" v-model="search" class="form-control filter-list-input" placeholder="Rechercher" aria-label="Rechercher">
                        </div>
                    </form>
                    <div class="chat-module-body" style="min-height: 200px;">
                        <ChatMessage
                            v-for="message in filteredMessages"
                            :key="message.id"
                            v-bind="message"
                        />
                        <Loading :size="3" label="Chargement..." v-if="loading"/>
                    </div>
                </div>
                <div class="chat-module-bottom" v-if="canAdd">
                    <form class="chat-form" data-children-count="2" @submit.prevent="sendMessage">
                        <div class="input-group">
                            <input class="form-control" v-model="newMessage" placeholder="Tapez votre message" type="text"/>
                            <button type="submit" class="btn btn-link">
                                <i class="material-icons">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {SocketService} from '@/services/socket'
import ChatMessage from '@/views/components/SideBar/SideBarChatMessage'
import api from '@/services/api'
import {mapGetters} from 'vuex'
import Loading from '@/views/components/Loading'

export default {
    name: 'SideBarChat',
    components: {
        ChatMessage,
        Loading
    },
    props: [
        "contact",
        "procedure",
        "canAdd"
    ],
    data() {
        return {
            newMessage: '',
            search: '',
            messages: [],
            loading: true
        }
    },
    computed:{
        ...mapGetters([
            'role',
            'sidebarChat',
            'dossier'
        ]),
        filteredMessages(){
            const messages =  this.messages.filter(message => {
                const messageFieldToSearch = ['description', 'auteur'];
                let includeMessage = false;
                messageFieldToSearch.forEach(messageField => {
                    if (message[messageField].toLowerCase().includes(this.search.toLowerCase())) {
                        includeMessage = true;
                    }
                });
                return includeMessage;
            });

            if(this.contact && this.procedure.dateDebut){
                messages.unshift({
                    id: 'intro',
                    description: 'Vous avez des commentaires à faire sur le document ? Faites les ici ! Ces commentaires seront envoyés au syndic et à tous les autres validateurs du document. Attention, une fois que vous avez refusez ou validez le document, vous ne pourrez plus écrire de commentaires.',
                    date: this.$moment(this.procedure.dateDebut).toDate(),
                    auteur: 'Paraf'
                });
            }
            return messages;
        },
    },
    methods: {
        sendMessage(e) {
            // Si le message est vide, je passe mon chemin
            if(this.newMessage == "") return false;
            // Sinon
            this.loading = true;
            let promise = null;
            if(!this.contact){
                promise = api.postEvenement(this.dossier.id, this.procedure.id, {description: this.newMessage});
            }else{
                promise = api.postEvenementContact(this.procedure.hash, this.contact.hash, {description: this.newMessage});
            }
            promise.finally(() => {
                this.newMessage = '';
                this.loading = false;
            });
            return false;
        },
        pushNewEvenement(evenement){
            if(!this.isChat(evenement)){
                return false;
            }
            let auteur = 'inconnu•e';
            let me = false;

            if(evenement.contact){
                auteur = `${evenement.contact.prenom} ${evenement.contact.nom}`;
                if(this.contact && evenement.contact.id == this.contact.id){
                    me = true;
                }
            }else if(evenement.user){
                auteur = evenement.user.libelle;
                if(this.$store.state.user && this.$store.state.user.id == evenement.user.id){
                    me = true;
                }
            }

            const message = {
                id: evenement.id,
                description: evenement.description,
                date: this.$moment(evenement.date).toDate(),
                auteur: auteur,
                me: me
            };

            this.messages.push(message);

            //mise a jour du store si on estime que le message est plus recent que le dernier lu
            if(!this.sidebarChat && (!this.$store.getters.lastReadChatMessageId || this.$store.getters.lastReadChatMessageId < message.id)){
                this.$store.commit('addUnreadMessage', message);
            }

            this.messages.sort((a, b) => { return a.date > b.date ? -1 : 1;})
        },
        isChat(evenement){
            return [api.EVENEMENT_TYPE_GESTIONNAIRE, api.EVENEMENT_TYPE_CONTACT].indexOf(evenement.type) > -1;
        }
    },
    watch: {
        sidebarChat: function(newDisplay, oldDisplay) {
            if(newDisplay && this.messages.length){
                this.$store.commit('setLastReadChatMessage', this.messages[0]);
                this.$store.commit('resetCountUnreadChatMessage');
            }
        }
    },
    beforeMount() {
        SocketService.getInstance('/procedure/'+this.procedure.hash).addListener(SocketService.EVENT_EVENEMENT_NEW, this.pushNewEvenement, this);
        this.$store.commit('resetCountUnreadChatMessage');
        if(!this.contact){
            api.getEvenements(this.dossier.id, this.procedure.id)
                .then ((evenements) => {
                    evenements.forEach((evenement) => {
                        this.pushNewEvenement(evenement);
                    })
                    this.loading = false;
                })
            ;
        }else{
            api.getEvenementsContact(this.procedure.hash)
                .then ((evenements) => {
                    evenements.forEach((evenement) => {
                        this.pushNewEvenement(evenement);
                    })
                    this.loading = false;
                })
            ;
        }
    }
}
</script>
