<template>
  <b-col md="12" v-if="procedure" class="mt-2">
    <b-row v-if="failed">
      <b-col md="12" class="mt-2">
        <div class="alert alert-danger">
          <span v-if="estErrorPhoneYousign">
            Un numéro de téléphone semble invalide
          </span>
          <span v-else-if="contactErreur">
            Les caractères spéciaux ne sont pas autorisés dans le prénom et nom des contacts. <br>
            <span v-for="contact in contactInvalide" :key="contact.id">
              {{ contact.prenom }} {{ contact.nom }} <br>
            </span>
          </span>
          <span v-else>
            Une erreur est survenue si le problème persiste essayez de recharger ce contenu
            <b-badge class="bg-white text-primary" @click="reload" style="cursor: pointer" v-b-tooltip.hover.bottom title="Rafraîchir la page">RAFRAÎCHIR <font-awesome-icon icon="sync-alt"/></b-badge>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="6" offset-md="3" class="mb-2">
        <p v-if="procedure.contacts[signatures.length]">
          Signature de <strong>{{ procedure.contacts[signatures.length].prenom }} {{ procedure.contacts[signatures.length].nom }}</strong> : <br/>
          <ul>
            <li class="list-unstyled">1. Sélectionnez la page souhaitée</li>
            <li class="list-unstyled">2. Cliquez sur la page, l'encart de signature apparaitra (pour changer l'emplacement, re cliquez où vous souhaitez dans la page)</li>
            <li class="list-unstyled">3. Cliquez sur "Ajouter la signature"</li>
            <li class="list-unstyled" v-if="procedure.contacts.length == 1">4. Cliquez sur "Envoyer la procédure de signature"</li>
          </ul>
          <span v-if="procedure.contacts.length != 1">
            Cette manipulation vous sera demandée pour chaque contact signataire. Une fois tous les emplacements des signatures apposés vous pourrez cliquer sur "Envoyer la procédure de signature".
          </span>
        </p>
      </b-col>
      <b-col md="6" offset-md="3" class="mt-3">
        <b-btn 
          @click="confirmSignature"
          variant="primary" 
          class="float-right mr-2" 
          :disabled="pushing"
          v-if="signatures.length < procedure.contacts.length && signature.page"
        >
          Ajouter la signature
        </b-btn>
        <b-btn 
          @click="commencerProcedure" 
          variant="primary" 
          class="float-right mr-2" 
          :disabled="procedure.contacts.length === 0 || procedure.documents.length === 0 || pushing"
          v-else-if="signatures.length == procedure.contacts.length"
        >
          <b-spinner small type="grow" v-if="pushing"></b-spinner>
          Envoyer la procédure de signature
        </b-btn>
        <b-btn 
          @click="signatures.pop()"
          variant="info" 
          class="float-right mr-2" 
          v-if="signatures.length"
          :disabled="pushing"
        >
          Enlever la dernière signature
        </b-btn>
        <b-btn 
          :to="{ name: 'iframe_procedure_modifier'}"
          variant="info" 
          class="float-right mr-2" 
          v-else-if="signatures.length === 0"
          :disabled="pushing"
        >
          Annuler
        </b-btn>
      </b-col>
      <b-col md="6" offset-md="3" class="bg-white p-1" v-if="nbPages">
        <b-btn-group class="w-100">
          <b-btn variant="info" @click="page = 1" :disabled="page===1"><<</b-btn>
          <b-btn variant="info" @click="page = page-1" :disabled="page===1">
            <span v-if="page === 1 ">-</span>
            <span v-else>{{ page-1 }}</span>
          </b-btn>
          <b-btn variant="primary" disabled>{{ page }} / {{ nbPages }}</b-btn>
          <b-btn variant="info" @click="page = page+1" :disabled="page===nbPages">
            <span v-if="page === nbPages">-</span>
            <span v-else>{{ page+1 }}</span>
          </b-btn>
          <b-btn variant="info" @click="page = nbPages" :disabled="page===nbPages">>></b-btn>
        </b-btn-group>
      </b-col>
      <b-col md="6" offset-md="3" class="bg-light p-1">
        <PdfViewer 
          :labelSignature="procedure.contacts[signatures.length] ? `${procedure.contacts[signatures.length].prenom} ${procedure.contacts[signatures.length].nom}` : ''" 
          :askSignature="signatures.length < procedure.contacts.length"
          :src="url" 
          :page="page" 
          v-if="url" 
          @signature="(e) => {signature = e}" 
          @nbPages="(e) => {page = nbPages = e}" 
          :signatures="signatures"
        >
          <template slot="footer">
            <div class="text-center">
              <span v-if="signature.page">
                <span class="text-right">Page : {{ signature.page }}</span> | 
                <span class="text-right">Point Gauche-Bas : [{{ signature.pos[0] }}, {{ signature.pos[1] }}]</span> | 
                <span class="text-right">Point Droit-Haut : [{{ signature.pos[2] }}, {{ signature.pos[3] }}]</span>
              </span>
              <span v-else>
                Aucun emplacement défini
              </span>
            </div>
          </template>
          <template slot="header">
            <p v-for="contact in procedure.contacts" :key="contact.id">
              <strong>
                {{ contact.prenom }} {{contact.nom }} 
                <b-badge variant="secondary" v-for="role in contact.roles" :key="role">
                  {{ role }}
                </b-badge>
               :

              </strong>
              
              <span v-if="getPosSignature(contact)">
                Page : {{ getPosSignature(contact).page}}
              </span>
              <span v-else>
                Signature non précisée
              </span>
            </p>
            <div class="text-center small">
              <span v-if="signature.page">
                <span class="text-right">Page : {{ signature.page }}</span> | 
                <span class="text-right">Point Bas-Gauche : [{{ signature.pos[0] }}, {{ signature.pos[1] }}]</span> | 
                <span class="text-right">Point Haut-Droit : [{{ signature.pos[2] }}, {{ signature.pos[3] }}]</span>
              </span>
              <span v-else>
                Aucun emplacement défini
              </span>
            </div>
          </template>
          <template slot="loading">
            <Loading :size=3 />
          </template>
        </PdfViewer>
      </b-col>
      <b-col md="6" offset-md="3" class="mt-3">
        <b-btn 
          @click="confirmSignature"
          variant="primary" 
          class="float-right mr-2" 
          :disabled="pushing"
          v-if="signatures.length < procedure.contacts.length && signature.page"
        >
          Ajouter la signature
        </b-btn>
        <b-btn 
          @click="commencerProcedure" 
          variant="primary" 
          class="float-right mr-2" 
          :disabled="procedure.contacts.length === 0 || procedure.documents.length === 0 || pushing"
          v-else-if="signatures.length == procedure.contacts.length"
        >
          <b-spinner small type="grow" v-if="pushing"></b-spinner>
          Envoyer la procédure de signature
        </b-btn>
        <b-btn 
          @click="signatures.pop()"
          variant="info" 
          class="float-right mr-2" 
          v-if="signatures.length"
          :disabled="pushing"
        >
          Enlever la dernière signature
        </b-btn>
        <b-btn 
          :to="{ name: 'iframe_procedure_modifier'}"
          variant="info" 
          class="float-right mr-2" 
          v-else-if="signatures.length === 0"
          :disabled="pushing"
        >
          Annuler
        </b-btn>
      </b-col>
    </b-row>
    <b-row v-if="failed">
      <b-col md="12" class="mt-2">
        <div class="alert alert-danger">
          <span v-if="estErrorPhoneYousign">
            Un numéro de téléphone semble invalide
          </span>
          <span v-else>
            Une erreur est survenue si le problème persiste essayez de recharger ce contenu
            <b-badge class="bg-white text-primary" @click="reload" style="cursor: pointer" v-b-tooltip.hover.bottom title="Rafraîchir la page">RAFRAÎCHIR <font-awesome-icon icon="sync-alt"/></b-badge>
          </span>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import api from "@/services/api";
import { mapGetters } from "vuex";
import { SocketService } from '@/services/socket';
import Loading from '@/views/components/Loading'
import PdfViewer from '@/views/components/PdfViewer'
import { estUnNominatifValide } from '@/helpers/Validation'

export default {
  name: "IframeProcedureModifierSignatures",
  components: {
    Loading,
    PdfViewer
  },
  data() {
    return {
      failed: false,
      contactErreur: false,
      contactInvalide:[],
      pushing: false,
      signature: {
        page: false,
        pos: []
      },
      page: 1,
      nbPages: 0,
      signatures: [],
      estErrorPhoneYousign: false
    }
  },
  computed: {
    ...mapGetters(["dossier"]),
    procedure() {
      return this.dossier.procedures.find(
        item => item.id === parseInt(this.$route.params.procedureId)
      );
    },
    url(){
      return this.procedure ? this.procedure.documents.find((document) => document.type === api.DOCUMENT_TYPE_PRINCIPAL).url : false;
    }
  },
  methods: {
    getPosSignature: function(contact){
      return this.signatures.find((signature) => signature.contact.id === contact.id);
    },
    confirmSignature: function(){
      this.signatures.push({
        page: this.signature.page,
        pos: this.signature.pos,
        label: `${this.procedure.contacts[this.signatures.length].prenom} ${this.procedure.contacts[this.signatures.length].nom}`,
        contact: this.procedure.contacts[this.signatures.length]
      });
      this.signature.page = 0;
      this.signature.pos = [];
    },
    reload: function(){
      window.location.reload();
    },
    commencerProcedure: async function(){
      this.pushing = true;
      this.failed = false;
      this.contactErreur = false;
      this.contactInvalide = [];

      if(!this.procedure.contacts.every((contact) => estUnNominatifValide(contact.nom) && estUnNominatifValide(contact.prenom))){
        this.contactInvalide = this.procedure.contacts.filter((contact) => !estUnNominatifValide(contact.nom) || !estUnNominatifValide(contact.prenom));
        this.contactErreur = true;
        this.failed = true;
        this.pushing = false;
        return;
      }

      await Promise.all(
        this.procedure.contacts.map((contact) => {
          return contact.blocsSignatures.map((blocSignature) => {
            return api.deleteBlocSignature(this.dossier.id, this.procedure.id, contact.id, blocSignature.id);
          })
        })
      );

      await Promise.all(
        this.signatures.map((signature) => {
          return api.postBlocSignature(
            this.dossier.id, 
            this.procedure.id, 
            signature.contact.id, 
            {
              position: signature.pos,
              page: signature.page,
              documentId: this.procedure.documents.find((document) => document.type === api.DOCUMENT_TYPE_PRINCIPAL).id
            }
          )
          .catch((error) => {
            this.failed = true;
            this.pushing = false;
          })
          ;
        })
      );

      if(!this.failed){
        this.estErrorPhoneYousign = false;
        api
          .commencerProcedure(this.dossier.id, this.procedure.id)
          .then(() => {
            parent.postMessage({
              name: 'procedure.START',
              value: this.procedure.id
            }, "*");
            this.$router.push({ name: 'iframe_procedure'})
          })
          .catch((error) => {
            if(error.response && error.response.data.message && error.response.data.message.search(/phone/) !== -1){
              this.estErrorPhoneYousign = true;
            }
            this.failed = true;
          })
          .finally(() => {
            this.pushing = false;
          })
      }
    },
  },
  async beforeMount() {
    this.$store.commit('setProcedure', this.procedure);
    
    if(this.procedure.status !== api.PROCEDURE_STATUT_BROUILLON){
      this.$router.push({name: 'iframe_procedure'})
    }

    this.signatures = [];

    this.procedure.contacts.forEach((contact) => {
      if(contact.blocsSignatures.length){
        contact.blocsSignatures.forEach((blocSignature) => {
          this.signatures.push({
            page: blocSignature.page,
            pos: blocSignature.position,
            label: `${contact.prenom} ${contact.nom}`,
            contact: contact
          });
        })
      }
    })

    SocketService
      .getInstance("/procedure/" + this.procedure.hash)
      .addListener(
        SocketService.EVENT_PROCEDURE_UPDATE,
        function(newProcedure){
          const dossier = this.dossier;
          dossier.procedures = dossier.procedures.filter((item) => item.id !== newProcedure.id);
          dossier.procedures.push(newProcedure);

          this.$store.commit('setDossier', null);
          this.$store.commit('setDossier', dossier);
        },
        this
      );
  }
};
</script>