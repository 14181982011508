<template>
  <LayoutBase v-bind:fullscreen="true">
      <PageTitle v-bind:title="`Bienvenue ${userName} sur Paraf 👋`" v-bind:description="'Votre Module de Signature Électronique<br/>Intuitif & Connecté.'">
        <b-button @click=connect class="btn btn-lg btn-block btn-warning" v-if="!isLoggedIn">
          Continuer avec votre compte Datacopro
        </b-button>
        <b-button @click=logout class="btn btn-lg btn-block btn-warning" v-if="isLoggedIn">
          Se déconnecter
        </b-button>
      </PageTitle>
  </LayoutBase>
</template>

<script>

import LayoutBase from '@/views/layouts/LayoutBase'
import PageTitle from '@/views/components/PageTitle'

import {mapGetters, mapActions, mapState} from 'vuex';

export default {
  name: 'Login',
  components: {
    LayoutBase,
    PageTitle
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
    userName(){
      let userName = '';
      if(this.$store.state.user){
        if(this.$store.state.user.firstname){
          userName += this.$store.state.user.firstname.trim();
        }
        if(this.$store.state.user.lastname){
          userName += ' '+this.$store.state.user.lastname.trim();
        }
      }

      return userName.trim();
    }
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    connect()
    {      
      var uri = process.env.VUE_APP_API_URL + '/auth/login';
      const redirect_uri = localStorage.getItem('redirect_login');
      if (redirect_uri) {
        uri = uri + '?redirect_uri=' + redirect_uri
        localStorage.clear()
      }else{
        uri = uri + '?redirect_uri=' + window.location.href;
      }
      window.location.href = uri;
    },
  }
}
</script>