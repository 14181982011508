<template>
  <b-tab :title="`Participants (${contacts.length})`">
    <b-list-group>
      <b-list-group-item v-for="contact in contacts" :key="contact.id">
        <div class="media align-items-center">
          <ul class="avatars">
            <li>
              <b-avatar
                size="2em"
                class="bg-primary"
              ></b-avatar>
            </li>
          </ul>
          <div class="media-body d-flex justify-content-between align-items-center ml-1">
            <div>
              {{ contact.prenom }} {{ contact.nom }}<br/>
              {{ contact.email }}<br/>
              {{ contact.telephone }}<br/>
              <strong v-if="contact.roles.length">
                {{ contact.roles.join(', ') }}
              </strong>
              <small v-if="contact.relances.length">
                Relances :
                <small>
                  {{ datesRelance(contact).join(', ') }}<br/>
                </small>
              </small>
              <b-badge :variant="getBadgeEtatContact(contact)" v-if="!estProcedureModifiable">{{ contact.etat }}</b-badge>
            </div>
            <div class="dropdown no-dropdown-carret">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                </template>
                <b-dropdown-item @click="supprimerContact(contact)" v-if="estProcedureModifiable">
                  Supprimer
                </b-dropdown-item>
                <b-dropdown-item @click="showModifierContactModal(contact)" v-if="estProcedureModifiable">
                  Modifier
                </b-dropdown-item>
                <b-dropdown-item @click="contactSelected = contact"  v-b-modal.modal-aide-lien v-if="contact.url">
                  Aide à la connexion du participant
                </b-dropdown-item>
                <b-dropdown-item @click="relancerContact(contact)" v-if="!estProcedureModifiable">
                  Relancer
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-modal id="modal-aide-lien" title="Aide pu l'accès du participant" size="lg" ok-only="">
      <div v-if="contactSelected">
        <p>
          Si ce participant rencontre des problèmes lors de la réception de son lien de participation à l’inscription, vous pouvez l'aider en lui fournissant directement son lien d’accès.
        </p>
        <p style="padding: 10px;line-height: 30px;font-size: 14pt;">
          {{ urlCode(contactSelected) }}
          <b-button variant="outline-primary" class="float-right" v-clipboard:copy="urlCode(contactSelected)">Copier l'url</b-button>
        </p>
      </div>
    </b-modal>
    <ModalContactModifier :procedure="procedure" :contact="contactSelected" v-bind:show="showedModifierContactModal" v-on:closed="showedModifierContactModal=false" v-on:hide="showedModifierContactModal=false"/>
  </b-tab>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api'
import ModalContactModifier from '@/views/components/Modal/ModalContactModifier'

export default {
  name: "TabProcedureContacts",
  props: ["contacts", "procedure"],
  components: {
    ModalContactModifier
  },
  data() {
    return {
      contactSelected: null,
      showedModifierContactModal: false,
    }
  },
  computed: {
    ...mapGetters(['dossier']),
    estProcedureModifiable(){
      return this.procedure.status === api.PROCEDURE_STATUT_BROUILLON;
    }
  },
  methods: {
    datesRelance: function(contact){
      return contact.relances.map(date => this.$moment(date).format('DD/MM/YYYY à HH:mm'))
    },
    supprimerContact: function(contact){
      api.deleteContact(this.dossier.id, this.procedure.id, contact.id)
    },
    relancerContact: function(contact){
      api.relancerContact(this.dossier.id, this.procedure.id, contact.id)
    },
    showModifierContactModal: function(contact){
      this.showedModifierContactModal = true;
      this.contactSelected = contact;
    },
    urlCode(contact){
      let routeData = this.$router.resolve({
        name: 'procedure_contact',
        params: {
          hashProcedure: this.procedure.hash,
          hashContact: contact.hash
        }
      });

      return process.env.VUE_APP_URL+routeData.href;
    },
    getBadgeEtatContact: function(contact){
      switch(contact.etat){
        case api.CONTACT_ETAT_ACCEPTATION:
          return 'success';
          break;
        case api.CONTACT_ETAT_REFUS:
          return 'danger';
          break;
      }
      return 'secondary';
    },
  }
}
</script>