<template>
  <LayoutIframe v-bind:loading="!dossier">
    <div class="row justify-content-center">
      <div class="col-xl-11 col-lg-11">
        <div class="row">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </LayoutIframe>
</template>

<script>
import LayoutIframe from '@/views/layouts/LayoutIframe'
import api from '@/services/api'
import {mapGetters} from 'vuex'
import Vue from 'vue'
import { SocketService } from '@/services/socket';

export default {
  name: 'Iframe',
  components: {
    LayoutIframe
  },
  computed: {
    ...mapGetters(['dossier']),
    size(){
      var body = document.body,
      html = document.documentElement;

      var height = Math.max( body.scrollHeight, body.offsetHeight, 
                            html.clientHeight, html.scrollHeight, html.offsetHeight );
      return height;
    }
  },
  methods: {
    updateStoreOnDossier(dossier){
      this.$store.commit('setDossier', dossier);
      this.$store.commit('setRole', 'gestionnaire');
    }
  },
  async beforeMount() {
    window.addEventListener('message', (e) => {
      if(event.data.name == 'height'){
        var elem = document.getElementById('app');

        e.source.postMessage({
          name: event.data.name,
          value: elem.clientHeight
        }, e.origin);
      }
    }, false);


    api.getDossier(parseInt(this.$route.params.dossierId))
      .then((dossier) => {
        this.updateStoreOnDossier(dossier);
      })
      .catch((error) => {
        this.$router.push('/404')
      });
  },
};
</script>