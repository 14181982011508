import vue from 'vue'
import vueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/pages/Login.vue'
import Home from '../views/pages/Home.vue'
import Error from '../views/pages/Error.vue'
import Redirecting from '../views/pages/Redirecting.vue'
import NetworkError from '../views/pages/NetworkError.vue'
import Dossiers from '../views/pages/Dossiers.vue'
import Dossier from '../views/pages/Dossier.vue'
import ContactProcedure from '../views/pages/Contact/ContactProcedure.vue'
import DossierAccueil from '../views/pages/Dossier/DossierAccueil.vue'
import DossierProcedure from '../views/pages/Dossier/DossierProcedure.vue'
import Iframe from '../views/pages/Iframe.vue'
import IframeProcedure from '../views/pages/Iframe/IframeProcedure.vue'
import IframeProcedureModifier from '../views/pages/Iframe/IframeProcedureModifier.vue'
import IframeProcedureModifierSignatures from '../views/pages/Iframe/IframeProcedureModifierSignatures.vue'
import api from './api'
import store from './store'

vue.use(vueRouter)

const beforeEnterGestionnaireIframe = (to: any, from: any, next: any) => {
  api.me()
    .then((user) => {
      store.commit('setUser', user);
      next();
    })
    .catch(() => {
      store.commit('setUser', null);
      var uri = process.env.VUE_APP_API_URL + '/auth/login';
      uri = uri + '?redirect_uri=' + window.location.href;
      window.location.href = uri;
    })
}
const beforeEnterGestionnaire = (to: any, from: any, next: any) => {
  api.me()
    .then((user) => {
      store.commit('setUser', user);
      next();
    })
    .catch(() => {
      store.commit('setUser', null);
      localStorage.setItem('redirect_login', window.location.href);
      next({name: 'login'});
    })
}

const routes = <Array<RouteConfig>>[
  {
    path:'/redirecting',
    name:'redirecting',
    component: Redirecting,
    meta: {
      title: 'Redirection',
    }
  },
  {
    path:'',
    name:'home',
    component:Home,
    meta: {
      title: 'Bienvenue',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Connexion',
    }
  },
  {
    path: '/mes-dossiers',
    name: 'dossiers',
    component: Dossiers,
    beforeEnter: beforeEnterGestionnaire,
    meta: {
      title: 'Mes dossiers',
    }
  },
  {
    path: '/mon-dossier/:dossierId',
    component: Dossier,
    beforeEnter: beforeEnterGestionnaire,
    children: [,
      {
        path: '',
        name: 'dossier',
        component: DossierAccueil,
        meta: {
          title: "Mon dossier",
        }
      },
      {
        path: 'procedure/:procedureId',
        name: 'dossier_procedure',
        component: DossierProcedure
      },
    ]
  },
  {
    path: '/ma-procedure/:hashProcedure/:hashContact',
    name: 'procedure_contact',
    component: ContactProcedure,
    meta: {
      title: "Ma procédure",
    }
  },
  {
    path: '/iframe',
    component: Iframe,
    beforeEnter: beforeEnterGestionnaireIframe,
    children: [,
      {
        path: ':dossierId/:procedureId',
        name: 'iframe_procedure',
        component: IframeProcedure
      },
      {
        path: ':dossierId/:procedureId/modifier',
        name: 'iframe_procedure_modifier',
        component: IframeProcedureModifier
      },
      {
        path: ':dossierId/:procedureId/modifier-signature',
        name: 'iframe_procedure_modifier_signatures',
        component: IframeProcedureModifierSignatures
      }
    ],
  },
  {
    path: '/down',
    name: 'down',
    meta: {
      title: 'Serveur indisponible'
    },
    component: NetworkError,
  },
  {
    path: '*',
    name: 'error',
    meta: {
      title: 'Une erreur est survenue'
    },
    component: Error,
  }
]

const router = new vueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
