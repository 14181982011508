import axios from './axios'
import { AxiosInstance } from 'axios';


interface DossierPut {
  libelle: string
}
interface ProcedurePut {
  libelle: string,
  type: string
}
interface ContactPut {
  prenom: string,
  nom: string,
  email: string,
  telephone: string,
  roles: string[]
}
interface BlocSignaturePost {
  documentId: Number,
  position: Number[],
  page: Number
}
interface DocumentPut {
  libelle: string,
  url: string,
  type: string
}

interface EvenementPost {
  description: string
}
class ApiParafService {

  public EVENEMENT_TYPE_ACTIVITE = 'activité';
  public EVENEMENT_TYPE_CONTACT = 'contact';
  public EVENEMENT_TYPE_GESTIONNAIRE = 'gestionnaire';

  public PROCEDURE_STATUT_FERMEE = 'fermée';
  public PROCEDURE_STATUT_OUVERTE = 'ouverte'; 
  public PROCEDURE_STATUT_BROUILLON = 'brouillon';
  
  public PROCEDURE_TYPE_SIGNATURE = 'signature';
  public PROCEDURE_TYPE_VALIDATION = 'validation';
  
  public CONTACT_ETAT_EN_COURS = 'en cours';
  public CONTACT_ETAT_REFUS = 'refus'
  public CONTACT_ETAT_ACCEPTATION = 'acceptation';

  public DOCUMENT_TYPE_PIECE_JOINTE = 'pièce jointe';
  public DOCUMENT_TYPE_PRINCIPAL = 'pièce principale';
  public DOCUMENT_TYPE_PREUVE = 'preuve';
  public DOCUMENT_TYPE_PRINCIPAL_SIGNE = 'pièce principale signée';

  private static instance: ApiParafService;
  protected client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public static getInstance(): ApiParafService {
    if (!ApiParafService.instance) {
      ApiParafService.instance = new ApiParafService(axios);
    }
    return ApiParafService.instance;
  }

  private get(url: string) : Promise<any>{
    return axios.get(url)
      .then ((response) => {
          return response.data
      })
  }

  private post(url: string, data: {}) {
    return axios.post(url, data)
        .then ((response) => {
          return response.data
      })
  }

  private put(url: string, data: {}) {
    return axios.put(url, data)
        .then ((response) => {
          return response.data
      })
  }

  private delete(url: string) {
    return axios.delete(url)
        .then ((response) => {
          return response.data
      })
  }

  /*
    API
  */
  public getDossiers() : Promise<any> {
    return this.get(`/dossiers`);
  }

  public getDossier(dossierId: number) : Promise<any> {
    return this.get(`/dossier/${dossierId}`);
  }

  public putDossier(dossierId: number, data: DossierPut) : Promise<any> {
    return this.put(`/dossier/${dossierId}`, data);
  }

  public postDossier(data: DossierPut) : Promise<any> {
    return this.post(`/dossier`, data);
  }


  public postEvenement(dossierId: number, procedureId: number, data: EvenementPost) : Promise<any> {
    return this.post(`/dossier/${dossierId}/procedure/${procedureId}/evenement`, data);
  }

  public getEvenements(dossierId: number, procedureId: number) : Promise<any> {
    return this.get(`/dossier/${dossierId}/procedure/${procedureId}/evenements`);
  }

  public putProcedure(dossierId: number, procedureId: number, data: ProcedurePut) : Promise<any> {
    return this.put(`/dossier/${dossierId}/procedure/${procedureId}`, data);
  }

  public postProcedure(dossierId: number, data: ProcedurePut) : Promise<any> {
    return this.post(`/dossier/${dossierId}/procedure`, data);
  }

  public deleteProcedure(dossierId: number, procedureId: number) : Promise<any> {
    return this.delete(`/dossier/${dossierId}/procedure/${procedureId}`);
  }

  public putContact(dossierId: number, procedureId: number, contactId: number, data: ContactPut) : Promise<any> {
    return this.put(`/dossier/${dossierId}/procedure/${procedureId}/contact/${contactId}`, data);
  }

  public deleteContact(dossierId: number, procedureId: number, contactId: number) : Promise<any> {
    return this.delete(`/dossier/${dossierId}/procedure/${procedureId}/contact/${contactId}`);
  }

  public relancerContact(dossierId: number, procedureId: number, contactId: number) : Promise<any> {
    return this.get(`/dossier/${dossierId}/procedure/${procedureId}/contact/${contactId}/relancer`);
  }

  public postContact(dossierId: number, procedureId: number, data: ContactPut) : Promise<any> {
    return this.post(`/dossier/${dossierId}/procedure/${procedureId}/contact`, data);
  }

  public postBlocSignature(dossierId: number, procedureId: number, contactId: number, data: BlocSignaturePost) : Promise<any> {
    return this.post(`/dossier/${dossierId}/procedure/${procedureId}/contact/${contactId}/bloc-signature`, data);
  }

  public deleteBlocSignature(dossierId: number, procedureId: number, contactId: number, blocSignatureId: number) : Promise<any> {
    return this.delete(`/dossier/${dossierId}/procedure/${procedureId}/contact/${contactId}/bloc-signature/${blocSignatureId}`);
  }

  public putDocument(dossierId: number, procedureId: number, documentId: number, data: DocumentPut) : Promise<any> {
    return this.put(`/dossier/${dossierId}/procedure/${procedureId}/document/${documentId}`, data);
  }

  public deleteDocument(dossierId: number, procedureId: number, documentId: number) : Promise<any> {
    return this.delete(`/dossier/${dossierId}/procedure/${procedureId}/document/${documentId}`);
  }

  public postDocument(dossierId: number, procedureId: number, data: DocumentPut) : Promise<any> {
    return this.post(`/dossier/${dossierId}/procedure/${procedureId}/document`, data);
  }

  public commencerProcedure(dossierId: number, procedureId: number) : Promise<any> {
    return this.get(`/dossier/${dossierId}/procedure/${procedureId}/commencer`);
  }

  public arreterProcedure(dossierId: number, procedureId: number) : Promise<any> {
    return this.get(`/dossier/${dossierId}/procedure/${procedureId}/arreter`);
  }
  
  /*
  Contact
  */
 
  public getProcedureContact(hashProcedure: string) : Promise<any> {
    return this.get(`/procedure/${hashProcedure}`);
  }
 
  public getContactContact(hashProcedure: string, hashContact: string) : Promise<any> {
    return this.get(`/procedure/${hashProcedure}/contact/${hashContact}`);
  }
 
  public getEvenementsContact(hashProcedure: string) : Promise<any> {
    return this.get(`/procedure/${hashProcedure}/evenements`);
  }

  public postEvenementContact(hashProcedure: string, hashContact: string, data: EvenementPost) : Promise<any> {
    return this.post(`/procedure/${hashProcedure}/contact/${hashContact}/evenement`, data);
  }
  
  /*
  Proxy
  */
 
  public getContactsDatacopro(dossierId: number) : Promise<any> {
    return this.get(`/datacopro/dossier/${dossierId}/contacts`);
  }
 
  public getDocumentsDatacopro(dossierId: number) : Promise<any> {
    return this.get(`/datacopro/dossier/${dossierId}/documents`);
  }
 
  /*
    AUTH
  */
  public me() : Promise<any> {
    return this.get('/auth/me');
  }

  public logout() : Promise<any> {
    return this.get('/auth/logout');
  }
}

export default ApiParafService.getInstance();