import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'

Vue.use(Vuex)

interface StateStore {
  dossier?: any;
  procedure?: any;
  user?: any;
  role?: any;

  sidebarChat: boolean;
  countUnreadChatMessage: number;
  countUnprocessedReclamation: number;
  lastReadChatMessage: number;

}

let user = sessionStorage.getItem('user');
if(user == 'undefined'){ //pour eviter les erreurs avec le parse derriere
  user = null;
}
if(user){
  user = JSON.parse(user);
}
if(user === undefined){
  user = null;
}

let role = sessionStorage.getItem('role');
if(role == 'undefined'){ //pour eviter les erreurs avec le parse derriere
  role = null;
}
if(role){
  role = role;
}
if(role === undefined){
  role = null;
}


export default new Vuex.Store({
  state: {
    dossier: null,
    procedure: null,
    user: user || null,
    role: role || null,

    sidebarChat: sessionStorage.getItem('sidebarChat') !== 'false',
    countUnreadChatMessage: 0,
    countUnprocessedReclamation: 0,
    lastReadChatMessage: parseInt(""+sessionStorage.getItem('lastReadChatMessage'))

  } as StateStore,
  getters: {
    isLoggedIn: (state) => {
      return state.user ? true : false
    },
    role: (state) => {
      return state.role;
    },
    user: (state) => {
      return state.user;
    },
    dossier: (state) => {
      return state.dossier;
    },
    procedure: (state) => {
      return state.procedure;
    },
    sidebarChat: (state) => {
      return state.sidebarChat;
    },
    countUnreadChatMessage: (state) => {
      return state.countUnreadChatMessage;
    },
    countUnprocessedReclamation: (state) => {
      return state.countUnprocessedReclamation;
    },
    lastReadChatMessageId: (state) => {
      return state.lastReadChatMessage;
    },
  },
  mutations: {
    setDossier (state, dossier) {
      state.dossier = dossier;
    },
    setProcedure (state, procedure) {
      state.procedure = procedure;
    },
    setUser (state, user) {
      state.user = user;
      if(user){
        sessionStorage.setItem('user', JSON.stringify(user))
      }else{
        sessionStorage.removeItem('user');
      }
    },
    setRole(state, role) {
      state.role = role;
      if(user){
        sessionStorage.setItem('role', role)
      }else{
        sessionStorage.removeItem('role');
      }
    },
    setSidebarChat (state, status) {
      state.sidebarChat = status;
      sessionStorage.setItem('sidebarChat', status);
    },
    addUnreadMessage(state, message){
      state.countUnreadChatMessage++;
    },
    setLastReadChatMessage(state, message){
      state.lastReadChatMessage = message.id;
      sessionStorage.setItem('lastReadChatMessage', message.id);
    },
    resetCountUnreadChatMessage(state){
      state.countUnreadChatMessage = 0;
    },
  },
  actions: {
    sidebarChat({state, commit}){
      return commit('setSidebarChat', state.sidebarChat ? false : true);
    },
    logout({commit}){
      return api.logout().then(() => {
        commit('setUser', null);
        commit('setRole', null);
      })
    },
    login({commit}){
      return api.me().then((user) => {
        if(user.id !== undefined){
          commit('setUser', user);
        }
      })
    }
  }
})