<template>
  <div class="card-list">
    <div class="card-list-head">
        <h6 @click="$router.push({name: 'dossier_procedure', params: {procedureId: procedure.id}})">
          [{{ procedure.type.toUpperCase() }}] {{ procedure.libelle }} <small>
             - créée le {{ procedure.dateCreation | moment('DD/MM/YY à HH:mm')}}
            <span v-if="procedure.dateDebut"> - lancée le {{ procedure.dateDebut | moment('DD/MM/YY à HH:mm')}}</span>
            
            <span v-if="estRefusee && procedure.dateFin"> - refusée le {{ procedure.dateFin | moment('DD/MM/YY à HH:mm')}}</span>
            <span v-else-if="!estOuverte && !estRefusee && procedure.dateFin"> - validée le {{ procedure.dateFin | moment('DD/MM/YY à HH:mm')}}</span>
          </small>
        </h6>
        <div class="dropdown no-dropdown-carret float-right" v-if="role == 'gestionnaire'">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
            </template>
            <b-dropdown-item v-if="procedure.documents.length" :to="{name:'iframe_procedure', params: {procedureId: procedure.id}}">
              Vue entière
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="showModifierProcedureModal" v-if="estModifiable">
              Modifier la procédure
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="supprimerProcedure" v-if="estModifiable">
              Supprimer la procédure
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="showModifierContactModal" v-if="estModifiable">
              Ajouter un participant
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="showModifierDocumentModal" v-if="estModifiable">
              Ajouter un document
            </b-dropdown-item>
            <b-dropdown-item :to="{name: 'dossier_procedure', params: {procedureId: procedure.id}}">
              Voir les détails
            </b-dropdown-item>
            <b-dropdown-item @click="commencerProcedure" v-if="estModifiable && procedure.contacts.length && procedure.documents.length">
              Lancer la procédure
            </b-dropdown-item>
            <b-dropdown-item @click="arreterProcedure" v-if="estOuverte">
              Arrêter la procédure
            </b-dropdown-item>
          </b-dropdown>
        </div>
    </div>
    <div class="card-list-body">
      <div class="card card-task">
        <b-progress :value="getValueProgressBar()" :max="getMaxProgressBar()" :striped="estOuverte" :variant="getVariantProgressBar()" :animated="getAnimatedProgressBar()" height="0.5rem"/>
        <b-card-body>
          <b-card-title>
            <!-- <a> tag only if simple resolution v-if="simple"-->
            <h6 data-filter-by="text">
              {{ procedure.contacts.length }} contact{{ procedure.contacts.length > 1 ? 's' : '' }}
               / 
              {{ procedure.documents.length }} document{{ procedure.documents.length > 1 ? 's' : '' }}           
              <small v-if="descriptionDerniereActivite">
                <br/>
                Dernière activité : 
                <span
                  :id="`tooltip-derniere-activite-${procedure.id}`"
                  data-filter-by="text"
                >
                  {{ dateDerniereActivite | moment("from") }}
                </span>
                <b-tooltip :target="`tooltip-derniere-activite-${procedure.id}`" triggers="hover">
                  {{ descriptionDerniereActivite }} <br/>
                  <small>le  {{ dateDerniereActivite | moment('DD/MM/YYYY à HH:mm:ss') }}</small>
                  <small v-if="auteurDerniereActivite"> par {{ auteurDerniereActivite }}</small>
                </b-tooltip>
              </small>
            </h6>
          </b-card-title>
        </b-card-body>
      </div>
    </div>
    <ModalProcedureModifier :procedure="procedure" v-bind:show="showedModifierProcedureModal" v-on:closed="showedModifierProcedureModal=false" v-on:hide="showedModifierProcedureModal=false"/>
    <ModalContactModifier :procedure="procedure" v-bind:show="showedModifierContactModal" v-on:closed="showedModifierContactModal=false" v-on:hide="showedModifierContactModal=false"/>
    <ModalDocumentModifier :procedure="procedure" v-bind:show="showedModifierDocumentModal" v-on:closed="showedModifierDocumentModal=false" v-on:hide="showedModifierDocumentModal=false"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api'
import ModalProcedureModifier from '@/views/components/Modal/ModalProcedureModifier'
import ModalContactModifier from '@/views/components/Modal/ModalContactModifier'
import ModalDocumentModifier from '@/views/components/Modal/ModalDocumentModifier'

export default {
  name: 'ProcedureSimple',
  props: ["procedure"],
  components: {
    ModalProcedureModifier,
    ModalContactModifier,
    ModalDocumentModifier,
  },
  data() {
    return {
      showedModifierProcedureModal: false,
      showedModifierContactModal: false,
      showedModifierDocumentModal: false,
      dateDerniereActivite: false,
      auteurDerniereActivite: false,
      descriptionDerniereActivite: false,
    }
  },
  computed: {
    ...mapGetters(['dossier', 'role']),
    estModifiable(){
      return this.procedure.status === api.PROCEDURE_STATUT_BROUILLON;
    },
    estRefusee() {
      return this.procedure
        ? this.procedure.contacts.find(contact => this.aRefuser(contact))
        : false;
    },
    estSignature() {
      return this.procedure
        ? this.procedure.type === api.PROCEDURE_TYPE_SIGNATURE
        : false;
    },
    estOuverte() {
      return this.procedure
        ? this.procedure.status === api.PROCEDURE_STATUT_OUVERTE
        : false;
    },
  },
  methods: {
    aRefuser: function(contact) {
      return contact.etat === api.CONTACT_ETAT_REFUS;
    },
    getVariantProgressBar: function() {
      if ([api.PROCEDURE_STATUT_OUVERTE].indexOf(this.procedure.status) > -1) {
        return 'warning';
      }
      if([api.PROCEDURE_STATUT_BROUILLON].indexOf(this.procedure.status) > -1){
        return 'white';
      }

      if([api.PROCEDURE_STATUT_FERMEE].indexOf(this.procedure.status) > -1){
        if(this.procedure.contacts.find(item => item.etat === api.CONTACT_ETAT_REFUS)){
          return 'danger';
        }else if(!this.procedure.contacts.find(item => item.etat !== api.CONTACT_ETAT_ACCEPTATION)){
          return 'success';
        }else if(this.procedure.dateDebut){
          return 'dark';
        }
      }
      return 'secondary';
    },
    getAnimatedProgressBar: function() {
      if ([api.PROCEDURE_STATUT_OUVERTE].indexOf(this.procedure.status) > -1) {
        return true;
      }
      return false;
    },
    getValueProgressBar: function() {
      if(!this.procedure.contacts.length){
        return 100;
      }
      const nbContactTotal = this.procedure.contacts.length;
      const nbContactOk = this.procedure.contacts.filter(item => item.etat === api.CONTACT_ETAT_ACCEPTATION).length;
      const nbContactRefus = this.procedure.contacts.filter(item => item.etat === api.CONTACT_ETAT_REFUS).length;
      if ([api.PROCEDURE_STATUT_OUVERTE].indexOf(this.procedure.status) > -1) {

        return nbContactTotal-nbContactOk;
      }
      if(nbContactRefus){
        return nbContactRefus;
      }
      return nbContactTotal;
    },
    getMaxProgressBar: function() {
      if(!this.procedure.contacts.length){
        return 100;
      }
      return this.procedure.contacts.length;
    },
    showModifierProcedureModal: function(){
      this.showedModifierProcedureModal = true;
    },
    showModifierContactModal: function(){
      this.showedModifierContactModal = true;
    },
    showModifierDocumentModal: function(){
      this.showedModifierDocumentModal = true;
    },
    commencerProcedure: function(){
      let messageBoxConfirm = `En lançant la procédure les ${this.procedure.contacts.length} participants vont être avertis par email et vous ne pourrez plus modifier ni les participants ni les documents joints. Êtes-vous prêt•e ?`;
      if(this.procedure.contacts.length === 1){
        messageBoxConfirm = `En lançant la procédure le participant sera averti par email et vous ne pourrez plus modifier ni les participants ni les documents joints. Êtes-vous prêt•e ?`;
      }
      this.$bvModal
          .msgBoxConfirm(
          messageBoxConfirm,
          {
            title: `Lancer la procédure de ${this.procedure.type}`,
            okTitle: "Ok, c'est compris",
            size: "lg",
            buttonSize: "lg",
            okVariant: "primary",
            cancelTitle: "Annuler",
          }
          )
          .then((value) => {
            if(value){
              api.commencerProcedure(this.dossier.id, this.procedure.id);
            }
          });
    },
    arreterProcedure: function(){
      let messageBoxConfirm = `En arrêtant la procédure les ${this.procedure.contacts.length} participants vont être avertis par email de la fin de la procédure. Êtes-vous prêt•e ?`;
      if(this.procedure.contacts.length === 1){
        messageBoxConfirm = `En arrêtant la procédure le participant sera averti de la fin de la procédure. Êtes-vous prêt•e ?`;
      }
      this.$bvModal
          .msgBoxConfirm(
          messageBoxConfirm,
          {
            title: `Arrêter la procédure de ${this.procedure.type}`,
            okTitle: "Ok, c'est compris",
            size: "lg",
            buttonSize: "lg",
            okVariant: "primary",
            cancelTitle: "Annuler",
          }
          )
          .then((value) => {
            if(value){
              api.arreterProcedure(this.dossier.id, this.procedure.id);
            }
          });
    },
    supprimerProcedure: function(){
      this.$bvModal
          .msgBoxConfirm(
          'Vous allez supprimer définitivement les données de cette procédure. Êtes-vous sûr•e ?',
          {
            title: `Supprimer la procédure de ${this.procedure.type}`,
            okTitle: "Ok, c'est compris",
            size: "lg",
            buttonSize: "lg",
            okVariant: "primary",
            cancelTitle: "Annuler",
          }
          )
          .then((value) => {
            if(value){
              api.deleteProcedure(this.dossier.id, this.procedure.id);
            }
          });
    },
  },
  beforeMount() {
    api.getEvenements(this.dossier.id, this.procedure.id).then((evenements) => {
      evenements.sort((a, b) => {
        return a.date < b.date ? 1 : -1;
      });
      if(evenements.length){
        const dernierEvenement = evenements.shift();
        this.descriptionDerniereActivite = dernierEvenement.description;
        this.dateDerniereActivite = dernierEvenement.date;
        this.auteurDerniereActivite = dernierEvenement.user ? dernierEvenement.user.libelle : false;
      }
    });
  }
}
</script>