<template>
  <b-col md="12" v-if="dossier">
    <ProcedureDetails :procedure="displayedProcedure" />
  </b-col>
</template>

<script>

import api from '@/services/api'
import ProcedureDetails from "@/views/components/Procedure/ProcedureDetails";
import {mapGetters} from 'vuex'

export default {
  name: 'DossierProcedure',
  components: {
    ProcedureDetails
  },
  computed: {
    ...mapGetters(['dossier']),
    displayedProcedure() {
      return this.$route.params.procedureId !== undefined
        ? this.dossier.procedures.find((item) => item.id === parseInt(this.$route.params.procedureId))
        : null;
    },
  },
  beforeMount() {
    this.$store.commit('setProcedure', this.displayedProcedure);
  }
};
</script>