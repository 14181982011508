<template>
  <LayoutBase v-bind:fullscreen="true">
      <PageTitle v-bind:title="`Une erreur s'est produite lors de la navigation !`" v-bind:description="'Revenez sur vos pas.'"/>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/views/layouts/LayoutBase'
import PageTitle from '@/views/components/PageTitle'

export default {
  name: 'Error',
  components: {
    LayoutBase,
    PageTitle
  }
}
</script>