<template>
  <b-modal id="modal-modifier-procedure-document" 
      :visible="show" 
      @hide="onHide"
      
      @show="showModal"
      @hidden="resetModal"
      :ok-title="document ? 'Modifier' : 'Ajouter'"
      cancel-title="Annuler"
      :ok-disabled="pushing"
      no-close-on-backdrop
    >
    <template #modal-header>
      <h5 class="modal-title">
        {{ document ? `Modifier le document ${document.prenom} ${document.nom}` : 'Ajouter un document à la procédure' }}
      </h5>
    </template>
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Préremplir avec un document de la copropriété"
        label-for="contact-datacopro-input"
        v-if="dossier.datacopro"
      >
        <v-select 
          v-if="!loadingDocumentsCopropriete"
          v-model="documentCopropriete" 
          label="libelle"
          :options="documentCoproprieteOptions" 
          id="documentCopropriete-input"
        ></v-select>
        <Loading v-else :size=3 />
        <hr/>
      </b-form-group>

      <b-form-group
        :state="libelleState"
        label="Libellé*"
        label-for="libelle-input"
        invalid-feedback="Le libellé est obligatoire"
      >
        <b-form-input
          id="libelle-input"
          v-model="libelle"
          :state="libelleState"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :state="urlState"
        label="Url*"
        label-for="url-input"
        invalid-feedback="L'url est obligatoire"
      >
        <b-form-input
          id="url-input"
          v-model="url"
          :state="urlState"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="typeState"
        label="Type*"
        label-for="type-input"
        invalid-feedback="Le type doit faire partie de la liste"
      >
        <b-form-select 
          v-model="type" 
          :options="typeOptions" 
          :state="typeState"
          value-field="value"
          text-field="text"
          id="type-input"
        ></b-form-select>
      </b-form-group>
    </b-form>
    <Loading v-if="pushing" :size=3 />
    <b-alert variant="danger" :show="failed">
      Une erreur s'est produite lors de la mise à jour
    </b-alert>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api'
import Loading from '@/views/components/Loading'

export default {
  name: 'ModalDocumentModifier',
  components: {
    Loading
  },
  props: ['procedure', 'document', 'show'],
  data() {
    return {
      failed: false,
      pushing: false,

      libelle: null,
      libelleState: null,

      url: null,
      urlState: null,

      type: null,
      typeState: null,
      typeOptions: [
        {
          text: 'Document principal',
          value: api.DOCUMENT_TYPE_PRINCIPAL,
        },
        {
          text: 'Pièce jointe',
          value: api.DOCUMENT_TYPE_PIECE_JOINTE,
        }
      ],

      loadingDocumentsCopropriete: false,
      documentCopropriete: null,
      documentCoproprieteOptions: []
    }
  },
  computed: {
    ...mapGetters([
        'dossier'
    ]),
  },
  methods: {
    showModal() {
      this.resetModal();
      if(this.document){
        this.libelle = this.document.libelle;
        this.url = this.document.url;
        this.type = this.document.type;
      }else{
        this.type = api.DOCUMENT_TYPE_PRINCIPAL;
      }
      if(this.dossier.datacopro){
        this.documentCoproprieteOptions = [];
        this.loadingDocumentsCopropriete = true;
        api.getDocumentsDatacopro(this.dossier.id)
          .then((documents) => {
            documents.forEach((documentLoop) => {
              this.documentCoproprieteOptions.push({
                id: documentLoop.id,
                libelle: documentLoop.libelle,
                url: documentLoop.url
              })
            })
          })
          .catch(() => this.failed = true)
          .finally(() => this.loadingDocumentsCopropriete = false)
      }
    },
    resetModal() {
      this.libelle = null;
      this.url = null;
      this.type = api.DOCUMENT_TYPE_PRINCIPA;

      this.libelleState = null;
      this.urlState = null;
      this.typeState = null;

      this.failed = false;
      this.pushing = false;
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity()
      if(!this.libelle || this.libelle.trim() == ''){
        this.libelleState = false;
        valid = false;
      }
      if(!this.url || this.url.trim() == ''){
        this.urlState = false;
        valid = false;
      }
      if(!this.type || this.type.trim() == ''){
        this.typeState = false;
        valid = false;
      }
      return valid
    },
    onHide(bvModalEvt) {
      if(bvModalEvt.trigger != 'ok'){
        this.$emit('hide');
      }else{
        this.handleOk(bvModalEvt);
      }
    },
    handleOk(bvModalEvt) {
      //pas de fermeture
      bvModalEvt.preventDefault()
      //submit le formulaire
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.pushing = true;
      let promise = null;
      if(this.document){
        promise = api.putDocument(this.dossier.id, this.procedure.id, this.document.id, {
          libelle: this.libelle,
          url: this.url,
          type: this.type
        });
      }else{
        promise = api.postDocument(this.dossier.id, this.procedure.id, {
          libelle: this.libelle,
          url: this.url,
          type: this.type
        });
      }
      promise
        .then(dossier => {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-modifier-procedure-document');
            this.$emit('hide');
          });
        })
        .catch((error) => {
          this.failed = true;
        })
        .finally(() => {
          this.pushing = false;
        })
    }
  },
  watch: {
    documentCopropriete(value){
      if(value){
        this.libelle = value.libelle;
        this.url = value.url;
        this.documentCopropriete = null;
      }
    }
  }
}
</script>