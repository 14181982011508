<template>
  <LayoutBase v-bind:fullscreen="true">
      <PageTitle v-bind:title="`Le serveur de Paraf est indisponible !`" v-bind:description="`Quand le problème sera rétabli vous serez redirigé automatiquement vers ${redirect_url}`"/>
      <Loading :size=3 />
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/views/layouts/LayoutBase'
import PageTitle from '@/views/components/PageTitle'
import Loading from '@/views/components/Loading'
import { SocketService } from '@/services/socket';

export default {
  name: 'NetworkError',
  components: {
    LayoutBase,
    PageTitle,
    Loading
  },
  data() {
    return {
      trying: false,
      redirect_url: false
    }
  },
  mounted () {
    this.redirect_url = localStorage.getItem('redirect_down');
    SocketService.getInstance('').on('connect', () => {
      // SERVER IS NO LONGER DOWN
      // redirect when server is up running again
      if(localStorage.getItem('redirect_down')){
        window.location.href = this.redirect_url;
      }else{
        this.$router.push({name: 'home'}).catch(()=>{});
      }
    })
  }
}
</script>