<template>
  <b-modal id="modal-modifier-dossier" 
      :visible="show" 
      @hide="onHide"
      
      @show="showModal"
      @hidden="resetModal"
      :ok-title="dossier ? 'Modifier' : 'Ajouter'"
      cancel-title="Annuler"
      :ok-disabled="pushing"
      no-close-on-backdrop
    >
    <template #modal-header>
      <h5 class="modal-title">
        {{ dossier ? `Modifier le dossier ${dossier.libelle}` : 'Ajouter un dossier' }}
      </h5>
    </template>
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="libelleState"
        label="Libellé*"
        label-for="libelle-input"
        invalid-feedback="Le libellé est obligatoire"
      >
        <b-form-input
          id="libelle-input"
          v-model="libelle"
          :state="libelleState"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>
    <Loading v-if="pushing" :size=3 />
    <b-alert variant="danger" :show="failed">
      Une erreur s'est produite lors de la mise à jour
    </b-alert>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api'
import Loading from '@/views/components/Loading'

export default {
  name: 'ModalDossierModifier',
  components: {
    Loading
  },
  props: ['dossier', 'show'],
  data() {
    return {
      failed: false,
      pushing: false,

      libelle: null,
      libelleState: null
    }
  },
  methods: {
    showModal() {
      this.resetModal();
      if(this.dossier){
        this.libelle = this.dossier.libelle;
      }
    },
    resetModal() {
      this.libelle = null;

      this.libelleState = null;

      this.failed = false;
      this.pushing = false;
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity()
      if(!this.libelle || this.libelle.trim() == ''){
        this.libelleState = false;
        valid = false;
      }
      return valid
    },
    onHide(bvModalEvt) {
      if(bvModalEvt.trigger != 'ok'){
        this.$emit('hide');
      }else{
        this.handleOk(bvModalEvt);
      }
    },
    handleOk(bvModalEvt) {
      //pas de fermeture
      bvModalEvt.preventDefault()
      //submit le formulaire
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.pushing = true;
      let promise = null;
      if(this.dossier){
        promise = api.putDossier(this.dossier.id, {
          libelle: this.libelle
        });
      }else{
        promise = api.postDossier({
          libelle: this.libelle
        });
      }
      promise
        .then(dossier => {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-modifier-dossier');
            this.$emit('hide');
            this.$store.commit('setDossier', dossier);
            this.$router.push({name: 'dossier', params: {dossierId: dossier.id}});
          });
        })
        .catch((error) => {
          this.failed = true;
        })
        .finally(() => {
          this.pushing = false;
        })
    }
  },
}
</script>