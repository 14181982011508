<template>
  <div>
    <div class="card-list-head">
        <h6 style="display: inline">
          {{ procedure.libelle }} - {{ procedure.type }}
        </h6>
        <div class="dropdown">
            <b-button :to="{name: 'dossier', params: {dossierId: dossier.id}}" class="btn-options">
                <i class="material-icons">arrow_left</i> <small>Revenir à la liste des procédures du dossier</small>
            </b-button>
        </div>
    </div>
    <b-tabs :key="`procedure-${procedure.id}-details-tabs`" content-class="mt-3" fill>
      <TabProcedureContacts :procedure="procedure" :contacts="procedure.contacts" />
      <TabProcedureDocuments :procedure="procedure" :documents="procedure.documents" />
      <TabProcedureActivites :procedure="procedure" :activites="activites" />
    </b-tabs>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api';
import TabProcedureDocuments from '@/views/components/Tab/TabProcedure/TabProcedureDocuments'
import TabProcedureContacts from '@/views/components/Tab/TabProcedure/TabProcedureContacts'
import TabProcedureActivites from '@/views/components/Tab/TabProcedure/TabProcedureActivites'
import { SocketService } from '@/services/socket';

export default {
  name: 'ProcedureDetails',
  props: ["procedure"],
  components: { 
    TabProcedureDocuments,
    TabProcedureActivites,
    TabProcedureContacts
  },
  data() {
    return {
      activites: [],
    };
  },
  computed: {
    ...mapGetters(['role', 'dossier']),
  },
  methods: {
    pushNewEvenement(evenement) {
        let auteur = "inconnu•e";
        if (evenement.contact) {
          auteur = `${evenement.contact.prenom} ${evenement.contact.nom}`;
        }else if (evenement.user) {
          auteur = evenement.user.libelle;
        }
        this.activites.push({
          description: evenement.description,
          date: this.$moment(evenement.date).toDate(),
          auteur: auteur,
          type: evenement.type,
          contexte: ''
        });
        this.activites.sort((a, b) => {
          return a.date > b.date ? 1 : -1;
        });
    },
  },
  beforeMount() {
    api.getEvenements(this.dossier.id, this.procedure.id).then((evenements) => {
      evenements.forEach((evenement) => {
        this.pushNewEvenement(evenement);
      });
      this.loading = false;
    });

    SocketService
      .getInstance("/procedure/" + this.procedure.hash)
      .addListener(
        SocketService.EVENT_EVENEMENT_NEW,
        this.pushNewEvenement,
        this
      );
  },
}
</script>

<style>
.no-dropdown-carret .dropdown-toggle::after {
 display: none !important;
}
</style>