<template>
  <div>
    <h3>
        {{ dossier.libelle }} <small> - crée le {{ dossier.dateCreation | moment('DD/MM/YY à HH:mm') }} </small>
        <div class="dropdown no-dropdown-carret float-right">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
              </template>
              <b-dropdown-item
                  v-b-tooltip.hover
                  title="Modifier des paramètres du dossier"
                  @click="showModifierDossierModal"
              >
                  Modifier le dossier
              </b-dropdown-item>
            </b-dropdown>
        </div>
    </h3>
    <div class="dropdown">
        <b-button :to="{name: 'dossiers'}" class="btn-options">
            <i class="material-icons">arrow_left</i> <small>Revenir à la liste des dossiers</small>
        </b-button>
    </div>
    <ModalDossierModifier :dossier="dossier" v-bind:show="showedModifierDossierModal" v-on:closed="showedModifierDossierModal=false" v-on:hide="showedModifierDossierModal=false"/>
  </div>
</template>

<script>
import { moment } from "vue-moment";
import { mapGetters } from "vuex";
import api from "@/services/api";
import ModalDossierModifier from '@/views/components/Modal/ModalDossierModifier'

export default {
  name: "DossierHeader",
  components: {
    ModalDossierModifier
  },  
  props: ['presence'],
  data() {
    return {
      showedModifierDossierModal: false,
    };
  },
  computed: {
    ...mapGetters(["dossier", "role"])
  },
  methods:{
    showModifierDossierModal: function(){
      this.showedModifierDossierModal = true;
    },
  }
};
</script>