<template>
  <LayoutBase v-bind:fullscreen="true">
     <div class="row justify-content-center">
      <div class="col border-right text-right">
        <h4>
          <strong>Bienvenue <span class="text-primary">{{userName}}</span><br />sur Paraf</strong>
        </h4>
        <p>Votre Module de Signature Électronique <br />Intuitif & Connecté.</p>
      </div>
      <div class="col">
        <h5>Vous avez un compte Datacopro ?</h5>        
        <b-button :to="{ name: 'dossiers' }" class="btn-block">ACCÉDER À MES DOSSIERS</b-button>
      </div>
    </div>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/views/layouts/LayoutBase'
import api from '@/services/api'

export default {
  name: 'Home',
  components: {
    LayoutBase
  },
  data() {
      return {
        page: 1
      }
  },
  computed: {
    userName(){
      let userName = '';
      if(this.$store.state.user){
        if(this.$store.state.user.firstname){
          userName += this.$store.state.user.firstname.trim();
        }
        if(this.$store.state.user.lastname){
          userName += ' '+this.$store.state.user.lastname.trim();
        }
      }

      return userName.trim();
    }
  },
}
</script>
