<template>
  <b-row class="justify-content-center">
        <div class="text-center">
            <h2><span v-html="title"></span></h2>
            <p class="lead" v-if="description"><span v-html="description"></span></p>
        </div>
        <slot />
  </b-row>
  
</template>

<script>
export default {
  name: 'PageTitle',
  props: ['title', 'description'],
}
</script>