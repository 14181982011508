<template>
  <div class="layout layout-nav-top bg-white">
    <Loading :size=10 label="Chargement..." v-if="loading"/>
    <slot v-if="!loading"/>
  </div>
</template>

<script>
import Loading from '@/views/components/Loading'

export default {
  name: "LayoutIframe",
  props: {
    loading: {
      default: false
    }
  },
  components: {
    Loading
  },
};
</script>