import io from 'socket.io-client';


export class SocketService {
    static defaultEvents = [
        'connect',
        'error',
        'disconnect',
        'reconnect',
        'reconnect_attempt',
        'reconnecting',
        'reconnect_error',
        'reconnect_failed',
        'connect_error',
        'connect_timeout',
        'connecting',
        'ping',
        'pong'
    ];

    static EVENT_EVENEMENT_NEW = 'evenement.NEW';
    static EVENT_EVENEMENT_UPDATE = 'evenement.UPDATE';

    static EVENT_PROCEDURE_UPDATE = 'procedure.UPDATE';
    static EVENT_PROCEDURE_DELETE = 'procedure.DELETE';

    protected static sockets = new Map();
    public static getInstance(namespace: string): SocketService {
        if (!SocketService.sockets.has(namespace)) {
            SocketService.sockets.set(namespace, new SocketService(namespace));
        }
        return SocketService.sockets.get(namespace);
    }


    protected emittedDates = new Map();
    protected listeners = new Map();
    protected socket: any;
    protected namespace: String;

    constructor(namespace: String){
        this.socket = io(process.env.VUE_APP_API_URL+namespace);
        this.namespace = namespace;
        this.register(this.socket);
    }

    public addListener(event: string, callback: any, component: any){
        if(typeof callback === 'function'){
            if (!this.listeners.has(event)) this.listeners.set(event, []);
            this.listeners.get(event).push({ namespace: this.namespace, callback, component });
        }
    }

    public removeListener(event: string, component: any){
        if(this.listeners.has(event)){
            const listeners = this.listeners.get(event).filter((listener: any) => (
                listener.component !== component
            ));
            if (listeners.length > 0) {
                this.listeners.set(event, listeners);
            } else {
                this.listeners.delete(event);
            }
        }
    }

    public register(socket: any){
        [
            SocketService.EVENT_EVENEMENT_NEW,
        ].forEach(event => socket.on(event, (args: any) => this.onEvent(event, args)));

        [
            SocketService.EVENT_EVENEMENT_UPDATE,
            SocketService.EVENT_PROCEDURE_UPDATE,
            SocketService.EVENT_PROCEDURE_DELETE,
        ].forEach(event => socket.on(event, (args: any) => {
            //gestion des évènements en retard
            if(args.id !== undefined && args._timestamp !== undefined){
                const emmittedValue = event.replace(/.DELETE/, '').replace(/.UPDATE/, '')+`.${args.id}`;
                if(this.emittedDates.has(emmittedValue) && this.listeners.get(emmittedValue) > args._timestamp){
                    return;
                }
                this.emittedDates.set(emmittedValue, args._timestamp);
            }
            this.onEvent(event, args)
        }));

        SocketService.defaultEvents.forEach(event => socket.on(event, (args: any) => this.onEvent(event, args)))
    }

    public onEvent(event: string, args: any){
        this.emit(event, args);
    }
    public emit(event: string, args: any){
        if(this.listeners.has(event)){
            this.listeners.get(event).forEach((listener: any) => {
                if(listener.namespace == this.namespace){
                    listener.callback.call(listener.component, args);
                }
            });
        }
    }
}