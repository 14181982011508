<template>
  <LayoutSidebar v-bind:loading="!dossier" :fullscreen="false">
    <template v-slot:sidebars v-if="procedure">
      <SideBarChat :procedure="procedure" v-if="procedure && estValidationProcedure && (estOuverteProcedure || estAccepteeProcedure || estRefuseeProcedure)" :canAdd="estOuverteProcedure"/>
    </template>
    <div class="row justify-content-center mt-4">
      <div class="col-xl-11 col-lg-10">
        <div class="row">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </LayoutSidebar>
</template>

<script>
import LayoutSidebar from '@/views/layouts/LayoutSidebar'
import SideBarChat from '@/views/components/SideBar/SideBarChat'
import api from '@/services/api'
import {mapGetters} from 'vuex'
import Vue from 'vue'
import { SocketService } from '@/services/socket';
import { bus } from '@/main';

export default {
  name: 'Dossier',
  components: {
    LayoutSidebar,
    SideBarChat
  },
  computed: {
    ...mapGetters(['dossier', 'procedure']),
    estOuverteProcedure(){
      return this.procedure && this.procedure.status == api.PROCEDURE_STATUT_OUVERTE;
    },
    estRefuseeProcedure() {
      return this.procedure
          ? this.procedure.contacts.find(contact => this.aRefuser(contact))
          : false;
    },
    estAccepteeProcedure() {
      return this.procedure
          ? this.procedure.contacts.filter(contact => this.aAccepter(contact)).length === this.procedure.contacts.length
          : false;
    },
    estValidationProcedure(){
      return this.procedure && this.procedure.type === api.PROCEDURE_TYPE_VALIDATION;
    },
  },
  methods: {
    updateStoreOnDossier(dossier){
      this.$store.commit('setDossier', dossier);
      this.$store.commit('setRole', 'gestionnaire');
      return dossier;
    },
    addEventsSocket: function(procedure){
      SocketService
        .getInstance("/procedure/" + procedure.hash)
        .addListener(
          SocketService.EVENT_PROCEDURE_UPDATE,
          function(newProcedure){
            const dossier = this.dossier;
            dossier.procedures = dossier.procedures.filter((item) => item.id !== newProcedure.id);
            dossier.procedures.push(newProcedure);

            this.$store.commit('setDossier', null);
            this.$store.commit('setDossier', dossier);
          },
          this
        );
      SocketService
        .getInstance("/procedure/" + procedure.hash)
        .addListener(
          SocketService.EVENT_PROCEDURE_DELETE,
          function(newProcedure){
            const dossier = this.dossier;
            dossier.procedures = dossier.procedures.filter((item) => item.id !== newProcedure.id);

            this.$store.commit('setDossier', null);
            this.$store.commit('setDossier', dossier);
          },
          this
        );
    },
    aRefuser: function(contact) {
        return contact.etat === api.CONTACT_ETAT_REFUS;
    },
    aAccepter: function(contact) {
        return contact.etat === api.CONTACT_ETAT_ACCEPTATION;
    }
  },
  async beforeMount() {
    const dossier = await api.getDossier(parseInt(this.$route.params.dossierId))
      .then((dossier) => {
        return this.updateStoreOnDossier(dossier);
      })
      .catch((error) => {
        this.$router.push('/404')
      });

    dossier.procedures.forEach(this.addEventsSocket)
    bus.$on('procedure.NEW', (procedure) => {
      const dossier = this.dossier;
      if(!dossier.procedures.find(item => item.id === procedure.id)){
        dossier.procedures.push(procedure);
        this.$store.commit('setDossier', null);
        this.$store.commit('setDossier', dossier);
      }
      this.addEventsSocket(procedure);
    })
  },
};
</script>