<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from './services/axios'
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  methods: {
    checkApi()
    {
      axios.get('/health')
        .then(() => {
          if (this.$router.currentRoute.name === 'down') {
            const redirect = localStorage.getItem('redirect_down');
            if(redirect){
              window.location.href = redirect;
            }else{
              localStorage.removeItem('redirect_down');
              this.$router.push({name: 'home'}).catch(()=>{});
            }
          }else{
            setTimeout(this.checkApi, 60*5*1000);
            this.$store.dispatch('login'); //necessaire pour refresh le token si necessaire
          }
        })
        .catch ((response) => {
          if (this.$router.currentRoute.name !== 'down') {
            localStorage.setItem('redirect_down', window.location.href)
            this.$router.push({name: 'down'}).catch(()=>{});
          }
          setTimeout(this.checkApi, 2000);
        })
    },
  },
  data(){
    return {
      faviconLink: document.getElementById("favicon").href
    }
  },
  mounted()
  {
    this.checkApi()
  }
}
</script>

<style>
  @import './assets/css/theme.css';
</style>
