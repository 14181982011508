<template>
  <b-tab :title="`Documents (${documents.length})`">
    <b-list-group>
      <b-list-group-item v-for="document in documents" :key="document.id">
        <div class="media align-items-center">
          <ul class="avatars">
            <li>
              <div class="avatar bg-primary">
                <i class="material-icons">insert_drive_file</i>
              </div>
            </li>
          </ul>
          <div class="media-body d-flex justify-content-between align-items-center ml-1">
            <div>
              <a :href="document.url" target="_blank" data-filter-by="text" class="A-filter-by-text">
                {{ document.libelle }} <small>({{ document.type }})</small>
              </a>
            </div>
            <div class="dropdown no-dropdown-carret">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                </template>
                <b-dropdown-item :href="document.url" target="_blank">
                  Télécharger
                </b-dropdown-item>
                <b-dropdown-item @click="showModifierDocumentModal(document)" v-if="estProcedureModifiable">
                  Modifier
                </b-dropdown-item>
                <b-dropdown-item @click="supprimerDocument(document)" v-if="estProcedureModifiable">
                  Supprimer
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <ModalDocumentModifier :procedure="procedure" :document="documentSelected" v-bind:show="showedModifierDocumentModal" v-on:closed="showedModifierDocumentModal=false" v-on:hide="showedModifierDocumentModal=false"/>
  </b-tab>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api'
import ModalDocumentModifier from '@/views/components/Modal/ModalDocumentModifier'

export default {
  name: "TabProcedureDocuments",
  props: ["documents", "procedure"],
  components: {
    ModalDocumentModifier
  },
  data() {
    return {
      documentSelected: null,
      showedModifierDocumentModal: false,
    }
  },
  computed: {
    ...mapGetters(['dossier']),
    estProcedureModifiable(){
      return this.procedure.status === api.PROCEDURE_STATUT_BROUILLON;
    }
  },
  methods: {
    supprimerDocument: function(document){
      api.deleteDocument(this.dossier.id, this.procedure.id, document.id)
    },
    showModifierDocumentModal: function(document){
      this.showedModifierDocumentModal = true;
      this.documentSelected = document;
    },
  }
}
</script>