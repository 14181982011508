import axios, { AxiosInstance } from 'axios'

class ApiService {
  private static instance: AxiosInstance;
  public static getInstance(): AxiosInstance {
    if (!ApiService.instance) {
        ApiService.instance = axios.create({
          withCredentials: true,
          baseURL: process.env.VUE_APP_API_URL
        });
    }
    return ApiService.instance;
  }
}

export default ApiService.getInstance();