<template>
  <LayoutSidebar v-bind:loading="!procedure && !contact" :fullscreen="false">
    <template v-slot:sidebars v-if="procedure && contact && !estSignature">
      <SideBarChat :procedure="procedure" :contact="contact" v-if="contact && (estOuverte || estRefusee || estAcceptee)" :canAdd="estOuverte"/>
    </template>
    <div v-if="procedure">
      <PageTitle v-if="!procedure.dateDebut" v-bind:title="`<br/>Procédure de ${procedure.type} indisponible`" v-bind:description="'La procédure est en cours de création !<br/><br/>Revenez plus tard !'"/>
      <PageTitle v-else-if="procedure.dateFin && estAcceptee" v-bind:title="`<br/>Procédure de ${procedure.type} terminée`" v-bind:description="`Merci pour votre participation, tout le monde a bien ${estSignature ? 'signé' : 'validé'} !`"/>
      <PageTitle v-else-if="procedure.dateFin && estRefusee" v-bind:title="`<br/>Procédure de ${procedure.type} refusée`" v-bind:description="`Procédure de ${procedure.type} interrompue suite au refus`"/>
      <PageTitle v-else-if="procedure.dateFin" v-bind:title="`<br/>Procédure de ${procedure.type} annulée`" v-bind:description="`Procédure de ${procedure.type} interrompue suite à son annulation par le syndic`"/>
      <div class="row justify-content-center mt-4" v-if="contact && (estOuverte || estRefusee || estAcceptee)">
        <div class="col-xl-11 col-lg-10">
          <div class="row">
            <iframe :src="urlContact" :style="`width: 100%;height: ${heightIframe}px;border: none;`"/>
          </div>
        </div>
      </div>
    </div>
  </LayoutSidebar>
</template>

<script>
import LayoutSidebar from '@/views/layouts/LayoutSidebar'
import PageTitle from '@/views/components/PageTitle'
import SideBarChat from '@/views/components/SideBar/SideBarChat'
import api from '@/services/api'
import { SocketService } from '@/services/socket';

export default {
  name: 'ContactProcedure',
  components: {
    LayoutSidebar,
    SideBarChat,
    PageTitle
  },
  data: function(){
    return {
      procedure: null,
      contact: null,
      heightIframe: 500
    }
  },
  computed: {
    estRefusee() {
      return this.procedure
        ? this.procedure.contacts.find(contact => this.aRefuser(contact))
        : false;
    },
    estAcceptee() {
      return this.procedure
        ? this.procedure.contacts.filter(contact => this.aAccepter(contact)).length === this.procedure.contacts.length
        : false;
    },
    estSignature() {
      return this.procedure
        ? this.procedure.type === api.PROCEDURE_TYPE_SIGNATURE
        : false;
    },
    estOuverte() {
      return this.procedure
        ? this.procedure.status === api.PROCEDURE_STATUT_OUVERTE
        : false;
    },
    urlContact(){
      let url = this.contact.url;
      if(url.includes('yousign') && process.env.VUE_APP_YOUSIGN_SIGNATURE_UI_ID){
        url += (url.includes('?') ? '&' : '?') + 'signatureUi=/signature_uis/'+process.env.VUE_APP_YOUSIGN_SIGNATURE_UI_ID
      }
      return url;
    }
  },
  methods:{
    aRefuser: function(contact) {
      return contact.etat === api.CONTACT_ETAT_REFUS;
    },
    aAccepter: function(contact) {
      return contact.etat === api.CONTACT_ETAT_ACCEPTATION;
    },
    resizeIframe(){
      let newHeight = window.innerHeight-150;
      if(newHeight < 500){
        newHeight = 500;
      }
      this.heightIframe = newHeight;
      setTimeout(this.resizeIframe, 500)
    }
  },
  async beforeMount() {
    this.procedure = await api.getProcedureContact(this.$route.params.hashProcedure)
      .catch((error) => {
        this.$router.push('/404')
      });

    this.$store.commit('setProcedure', this.procedure);
    this.$store.commit('setRole', 'contact');

    this.contact = await api.getContactContact(this.$route.params.hashProcedure, this.$route.params.hashContact)
      .catch((error) => {
        this.$router.push('/404')
      });
    
    this.resizeIframe();
  },
};
</script>