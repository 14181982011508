<template>
  <div :class="`layout layout-nav-top ${sidebar ? 'layout-sidebar' : ''}`">
    <Header />
    <Loading :size=10 label="Chargement..." v-if="loading"/>
    <div :class="`main-container ${fullscreen ? 'fullscreen' : ''}`">
      <div :class="fullscreen ? 'container' : ''" :style="!fullscreen ? 'display: contents' : ''">
        <slot v-if="!loading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/views/components/Header'
import Loading from '@/views/components/Loading'

export default {
  name: "LayoutBase",
  props: {
    sidebar: {
      default: false
    },
    fullscreen: {
      default: false
    },
    loading: {
      default: false
    }
  },
  components: {
    Header,
    Loading
  },
};
</script>