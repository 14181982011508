<template>
    <div class="media chat-item">
        <div class="media-body" v-bind:class="[me ? 'alert-warning' : 'alert-primary']">
            <div class="chat-item-title">
                <span class="chat-item-author SPAN-filter-by-text" data-filter-by="text">{{ auteur }}</span>
                <small>{{ date | moment('from') }}</small>
            </div>
            <div class="chat-item-body DIV-filter-by-text" data-filter-by="text">
                <p>{{ description }}</p>

            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ChatMessage',
  props: ['description', 'date', 'auteur','me']
}
</script>
