<template>
  <div>
    <slot v-if="loading" name="loading"/>
    <slot name="header"/>
    <div id="viewerContainer" ref="container" @click="click">
      <div v-if="pdfViewer && pdfViewer.canvas">    
        <div class="mt-2" v-for="signature in signatures" v-if="signature.page === page">
          <div :style="`width: ${convertPagePositionToXY(signature.pos)[2]}px; position: absolute;z-index: 1000;background-color: grey;opacity: 0.1;height: ${convertPagePositionToXY(signature.pos)[3]}px;margin-left: ${convertPagePositionToXY(signature.pos)[0]}px;margin-top: ${convertPagePositionToXY(signature.pos)[1]}px;`"></div>
          <div :style="`font-size: ${convertPagePositionToXY(signature.pos)[3]/8}px;color: grey;width: ${convertPagePositionToXY(signature.pos)[2]}px; position: absolute;z-index: 1001;height: ${convertPagePositionToXY(signature.pos)[3]}px;margin-left: ${convertPagePositionToXY(signature.pos)[0]}px;margin-top: ${convertPagePositionToXY(signature.pos)[1]}px;text-align:center;padding: ${convertPagePositionToXY(signature.pos)[3]/4}px;border:1px solid #111; border-radius: 5px; border-style: dotted;font-weight: 800;display: flex;flex-direction: column;align-items: center;justify-content: center;`">
            {{ signature.label }}
          </div>
        </div>
      </div>
      <vue-resizable 
        dragSelector=".block" 
        style="position:absolute;z-index: 2000" 
        :left="posX" 
        :top="posY" 
        v-if="askSignature && page === pageSignature" 
        :width="widthSignature" 
        :height="heightSignature" 
        :fit-parent="true"
        :minHeight="20"
        :minWidth="20"
        
        @resize:move="handleSignature"
        @resize:start="handleSignature"
        @resize:end="handleSignature"
        @drag:move="handleSignature"
        @drag:start="handleSignature"
        @drag:end="handleSignature"
      >
        <div class="block" :style="`font-size: ${this.heightSignature/8}px`">
          {{ labelSignature }}
        </div>
      </vue-resizable>
      <resizeSensor :initial="true" @resize="resizeScale"/>
    </div>
    <slot name="footer"/>
  </div>
</template>
<script>
  'use strict';

  import { getDocument } from 'pdfjs-dist';
  import VueResizable from 'vue-resizable'
  import {
    DefaultAnnotationLayerFactory,
    DefaultTextLayerFactory,
    PDFFindController,
    PDFLinkService,
    PDFPageView,
    EventBus
  } from 'pdfjs-dist/web/pdf_viewer.js';
  import resizeSensor from 'vue-resize-sensor'
  import PdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
  function isPDFDocumentLoadingTask(obj) {
    return typeof (obj) === 'object' && obj !== null && obj.__PDFDocumentLoadingTask === true;
  }

  export function createLoadingTask(src, options) {
    var source;
    if (typeof (src) === 'string')
      source = {
        url: src
      };
    else if (typeof (src) === 'object' && src !== null)
      source = Object.assign({}, src);
    else
      throw new TypeError('invalid src type');

    var loadingTask = getDocument(source).promise;
    loadingTask.__PDFDocumentLoadingTask = true; // since PDFDocumentLoadingTask is not public

    if (options && options.onPassword)
      loadingTask.onPassword = options.onPassword;

    if (options && options.onProgress)
      loadingTask.onProgress = options.onProgress;

    return loadingTask;
  }

  export default {
    name: 'PdfViewer',
    createLoadingTask: createLoadingTask,
    components: {
      resizeSensor,
      VueResizable
    },
    props: {
      src: {
        type: [String, Object, Promise],
        default: '',
      },
      page: {
        type: Number,
        default: 1,
      },
      rotate: {
        type: Number,
        default: 0,
      },
      scale: {
        type: [Number, String],
        default: 'page-width',
      },
      resize: {
        type: Boolean,
        default: false,
      },
      annotation: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
      labelSignature:{
        type: String,
        default: 'Signature'
      },
      signatures:{
        type: Array,
        default: []
      },
      askSignature:{
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        internalSrc: this.src,
        pdf: null,
        pdfViewer: null,
        loading: true,
        posX: 0,
        posY: 0,
        pageSignature: 0,
        widthSignature: 0,
        heightSignature: 0,
        canvasLoaded: false
      }
    },
    watch: {
      //on enelve le block quand changement dans les signatures existantes
      signatures: function (val) {
        this.pageSignature = false;
      },
      pdf: function (val) {
        var pdfInfo = val.pdfInfo || val._pdfInfo
        this.$emit('numpages', pdfInfo.numPages);
      },
      page: function (val) {
        var self = this;
        this.pdf.getPage(val).then(function (pdfPage) {
          self.pdfViewer.setPdfPage(pdfPage);
          self.pdfViewer.draw();
        });
      },
      scale: function (val) {
        this.drawScaled(val);
      },
      rotate: function (newRotate) {
        if (this.pdfViewer) {
          this.pdfViewer.update(this.scale, newRotate);
          this.pdfViewer.draw();
        }
      },
    },
    mounted: function () {
      var self = this;
      if (!isPDFDocumentLoadingTask(self.internalSrc)) {
        self.internalSrc = createLoadingTask(self.internalSrc);
        self.$emit('loading', true);
      }

      var container = this.$refs.container;
      var eventBus = new EventBus();

      // (Optionally) enable hyperlinks within PDF files.
      self.pdfLinkService = new PDFLinkService({
        eventBus: eventBus,
        externalLinkTarget: 2
      });

      // (Optionally) enable find controller.
      self.pdfFindController = new PDFFindController({
        eventBus: eventBus,
        linkService: self.pdfLinkService
      });

      let annotationLayer = undefined,
        textLayer = undefined;

      if (self.annotation) {
        annotationLayer = new DefaultAnnotationLayerFactory();
      }
      if (self.text) {
        textLayer = new DefaultTextLayerFactory();
      }

      self.internalSrc
        .then(function (pdfDocument) {
          // Document loaded, retrieving the page.
          self.pdf = pdfDocument;
          self.$emit('nbPages', pdfDocument._pdfInfo.numPages);
          return pdfDocument.getPage(self.page)
        }).then(function(pdfPage) {
          // Creating the page view with default parameters.
          self.pdfViewer = new PDFPageView({
            container: container,
            id: self.page,
            scale: 1,
            defaultViewport: pdfPage.getViewport({
              scale: 1
            }),
            eventBus: eventBus,
            textLayerFactory: textLayer,
            annotationLayerFactory: annotationLayer,
          });

          // Associates the actual page with the view, and drawing it
          self.pdfViewer.setPdfPage(pdfPage);
          // Set up a scrollPageIntoView function for our links
          var viewer = {
            scrollPageIntoView: function(params) {
              // Send an event when clicking internal links so we can handle loading/scrolling to the correct page
              self.$emit('link-clicked', params);
            },
          };
          self.pdfLinkService.setDocument(self.pdf);
          self.pdfLinkService.setViewer(viewer);
          self.pdfFindController.setDocument(self.pdf);

          self.drawScaled(self.scale);

          self.loading = false;
          self.$emit('loading', false);
        }).catch(err => {
          self.$emit('error', err)
          self.loading = false
          self.$emit('loading', false);
        })
    },
    beforeDestroy() {
      var self = this;
      if (self.pdfViewer) {
        self.pdfViewer.destroy();
        self.pdfViewer = null;
      }
    },
    methods: {
      handleSignature: function(e){
        this.widthSignature = e.width;
        if(this.widthSignature < 20){
          this.widthSignature = 20;
        }
        this.heightSignature = e.height;
        if(this.heightSignature < 20){
          this.heightSignature = 20;
        }
        this.posX = e.left;
        this.posY = e.top;

        this.pageSignature = this.page;

        this.$emit('signature', {
          page: this.page,
          pos: this.convertXYToPagePosition(this.posX, this.posY, this.widthSignature, this.heightSignature)
        })
      },
      click: function(e){
        if(this.askSignature && this.widthSignature == 0){
          this.widthSignature = this.pdfViewer.canvas.offsetWidth/5;
          this.heightSignature = this.pdfViewer.canvas.offsetHeight/10;
        }
        if(e.target.isEqualNode(this.pdfViewer.canvas)){

          let rect = this.pdfViewer.canvas.getBoundingClientRect();
          this.posX = event.clientX - rect.left-this.widthSignature/2;
          this.posY = event.clientY - rect.top-this.heightSignature/2;

          if(this.posX < 0){
            this.posX = 0;
          }else if(this.posX > this.pdfViewer.canvas.offsetWidth-this.widthSignature){
            this.posX = this.pdfViewer.canvas.offsetWidth-this.widthSignature;
          }
          if(this.posY < 0){
            this.posY = 0;
          }else if(this.posY > this.pdfViewer.canvas.offsetHeight-this.heightSignature){
            this.posY = this.pdfViewer.canvas.offsetHeight-this.heightSignature;
          }
          
          this.pageSignature = this.page;

          this.$emit('signature', {
            page: this.page,
            pos: this.convertXYToPagePosition(this.posX, this.posY, this.widthSignature, this.heightSignature)
          })
        }
      },
      convertXYToPagePosition(x, y, width, height){
        //taille html
        const widthTot = this.$refs.container.offsetWidth;
        const heightTot = this.$refs.container.offsetHeight;

        //taille page
        const widthPdfTo = this.pdfViewer.pdfPage._pageInfo.view[2];
        const heightPdfTo = this.pdfViewer.pdfPage._pageInfo.view[3];

        const Ax = (widthPdfTo * (x/widthTot));
        const Ay = (heightPdfTo * ((heightTot-(y+height))/heightTot));
        const Bx = (widthPdfTo * ((x+width)/widthTot));
        const By = (heightPdfTo * ((heightTot-y)/heightTot));

        return [Ax.toFixed(), Ay.toFixed(), Bx.toFixed(), By.toFixed()];
      },
      convertPagePositionToXY(pos){
        //taille html
        const widthTot = this.$refs.container.offsetWidth;
        const heightTot = this.$refs.container.offsetHeight;

        //taille page
        const widthPdfTo = this.pdfViewer.pdfPage._pageInfo.view[2];
        const heightPdfTo = this.pdfViewer.pdfPage._pageInfo.view[3];

        const x = widthTot * pos[0]/widthPdfTo;
        const y = (heightTot - (heightTot*pos[3]/heightPdfTo));

        const width = widthTot*(pos[2]-pos[0])/widthPdfTo;
        const height = heightTot*(pos[3]-pos[1])/heightPdfTo;

        return [x.toFixed(), y.toFixed(), width.toFixed(), height.toFixed()];
      },
      calculateScale: function (width = -1, height = -1) {
        this.pdfViewer.update(1, this.rotate); // Reset scaling to 1 so that "this.pdfViewer.viewport.width" gives proper width;
        if (width === -1 && height === -1) {
          width = this.$refs.container.offsetWidth;
        }

        return width / this.pdfViewer.viewport.width;
      },
      calculateScaleHeight: function () {
        this.pdfViewer.update(1, this.rotate); // Reset scaling to 1 so that "this.pdfViewer.viewport.width" gives proper width;
        var height = this.$refs.container.offsetHeight;
        var parentel = this.$refs.container.parentElement.parentElement;
        return parentel.offsetHeight / height;
      },
      drawScaled: function (newScale) {
        if (this.pdfViewer) {
          if (newScale === 'page-width') {
            newScale = this.calculateScale();
            this.$emit("update:scale", newScale);
          }
          else if (newScale === 'page-height') {
            newScale = this.calculateScaleHeight();
            this.$emit("update:scale", newScale);
          }
          this.pdfViewer.update(newScale, this.rotate);
          // The SimpleLinkService from the DefaultAnnotationLayerFactory doesn't do anything with links so override with our LinkService after it is created
          if(this.annotation) {
            this.pdfViewer.annotationLayer = this.pdfViewer.annotationLayerFactory.createAnnotationLayerBuilder(this.pdfViewer.div, this.pdfViewer.pdfPage);
            this.pdfViewer.annotationLayer.linkService = this.pdfLinkService;
          }
          this.pdfViewer.draw();
          // The findController needs the text layer to have been created in the Draw() function, so link it in now
          if (this.text) {
            this.pdfViewer.textLayer.findController = this.pdfFindController;
          }
          this.loading = false;
          this.$emit('loading', false);
        }
      },
      resizeScale: function () {
        if (this.resize) {
          this.drawScaled('page-width');
        }
      }
    }
  }
</script>
<style src="pdfjs-dist/web/pdf_viewer.css"></style>
<style scoped>
.block {
  height: 100%;
  width: 100%;
  background-color: #0069d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border:1px solid #111; 
  border-radius: 5px; 
  border-style: dotted;
  font-weight: 800;
  color: white;
  opacity: 0.6;
}
</style>