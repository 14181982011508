<template>
  <LayoutBase v-bind:sidebar="true" v-bind:fullscreen="false" v-bind:loading="loading">

    <slot name="sidebars" v-if="!loading"/>

    <div class="content-container">
      <div class="container">
        <slot v-if="!loading"/>
      </div>
    </div>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/views/layouts/LayoutBase'
import {mapGetters} from 'vuex'

export default {
  name: "LayoutSidebar",
  components: {
    LayoutBase,
  },
  props: {
    loading: {
      default: false
    }
  },
  computed: {
    ...mapGetters(['role'])
  }
};
</script>