<template>
  <LayoutBase v-bind:fullscreen="true">
      <PageTitle v-bind:title="`Bienvenue sur Paraf`" v-bind:description="'Vous allez être redirigé !'"/>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/views/layouts/LayoutBase'
import PageTitle from '@/views/components/PageTitle'

export default {
  name: 'Redirecting',
  components: {
    LayoutBase,
    PageTitle
  }
}
</script>