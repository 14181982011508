<template>
  <div class="media align-items-center">
    <ul class="avatars">
      <li v-if="isActivite">
        <div class="avatar bg-primary">
          <i class="material-icons">playlist_add_check</i>
        </div>
      </li>
      <li v-else>
        <b-avatar size="2em" class="bg-primary"></b-avatar>
      </li>
    </ul>
    <div class="media-body">
      <div>
        <span class="h6" data-filter-by="text" v-if="contexte">
          {{ contexte }} :
          <br/>
        </span>
        <span>
          {{ description }}
        </span>
      </div>
      <span
        class="text-small"
        data-filter-by="text"
        v-b-tooltip.hover
        :title="date | moment('DD/MM/YYYY HH:mm:ss')"
        >
        {{ date | moment("from") }} - {{auteur}}
        </span
      >
    </div>
  </div>
</template>

<script>
import { moment } from "vue-moment";
import api from '@/services/api'

export default {
  name: "MediaActivite",
  props: ["date", "description", "auteur", "contexte", "type"],
  computed: {
    isActivite(){
      return this.type === api.EVENEMENT_TYPE_ACTIVITE;
    }
  }
}
</script>