<template>
  <b-tab :title="`Procédures (${procedures.length})`" active>
    <div class="row content-list-head">
      <div class="col">
        <h5>
          Liste des procédures du dossier
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
              </template>
              <b-dropdown-item
                  v-b-tooltip.hover
                  title="Ajouter une procédure"
                  @click="showModifierProcedureModal"
              >
                  Ajouter une procédure
              </b-dropdown-item>
            </b-dropdown>
        </h5>
        <ModalProcedureModifier v-bind:show="showedModifierProcedureModal" v-on:closed="showedModifierProcedureModal=false" v-on:hide="showedModifierProcedureModal=false"/>
      </div>
      <form class="col" v-on:submit.prevent>
        <div class="input-group input-group-round">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="material-icons">filter_list</i>
            </span>
          </div>
          <input
            v-model="proceduresSearch"
            type="search"
            class="form-control filter-list-input"
            placeholder="Rechercher dans les procédures"
            aria-label="Filter Tasks"
          />
        </div>
      </form>
    </div>
    <div class="content-list-body">
      <ProcedureSimple
        v-for="(procedure, index) in filteredProcedures"
        :key="index"
        :procedure="procedure"
      >
      </ProcedureSimple>
    </div>
  </b-tab>
</template>

<script>
import ProcedureSimple from "@/views/components/Procedure/ProcedureSimple";
import ModalProcedureModifier from "@/views/components/Modal/ModalProcedureModifier";

export default {
  name: "TabDossierProcedures",
  components: {
    ProcedureSimple,
    ModalProcedureModifier
  },
  props: ["procedures"],
  data() {
    return {
      proceduresSearch: "",
      showedModifierProcedureModal: false,
    };
  },
  computed: {
    filteredProcedures() {
      return this.procedures.filter((procedure) => {
        const procedureFieldToSearch = [
          "libelle",
          "type",
          "status"
        ];
        let includeProcedure = false;
        procedureFieldToSearch.forEach((procedureField) => {
          if (
            procedure[procedureField]
              .toString()
              .toLowerCase()
              .includes(this.proceduresSearch.toLowerCase())
          ) {
            includeProcedure = true;
          }
        });
        return includeProcedure;
      });
    },
  },
  methods:{
    showModifierProcedureModal: function(){
      this.showedModifierProcedureModal = true;
    },
  }
};
</script>