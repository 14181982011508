<template>

  <b-col md="12" v-if="dossier">
    <div class="page-header">
      <DossierHeader />
    </div>
    <b-tabs content-class="mt-3" fill>
      <TabDossierProcedures :procedures="proceduresSorted" />
      <TabDossierActivites :activites="activites" />
    </b-tabs>
  </b-col>
</template>

<script>

import api from "@/services/api";
import { SocketService } from "@/services/socket";
import { mapGetters } from "vuex";
import TabDossierProcedures from "@/views/components/Tab/TabDossier/TabDossierProcedures";
import TabDossierActivites from "@/views/components/Tab/TabDossier/TabDossierActivites";
import DossierHeader from "@/views/components/Dossier/DossierHeader";

export default {
  name: 'DossierAccueil',
  components: {
    TabDossierProcedures,
    TabDossierActivites,
    DossierHeader
  },
  data() {
    return {
      activites: [],
    };
  },
  computed: {
    ...mapGetters(['dossier']),
    proceduresSorted(){
      const tempDossier = {
        ...this.dossier
      };
      return tempDossier.procedures.sort((a, b) => a.dateCreation < b.dateCreation ? 1 : -1);
    }
  },
  methods: {
    pushNewEvenement(procedure) {
      return (evenement) => {
        let auteur = "inconnu•e";
        if (evenement.user) {
          auteur = evenement.user.libelle;
        }
        this.activites.push({
          description: evenement.description,
          date: this.$moment(evenement.date).toDate(),
          auteur: auteur,
          contexte: procedure.libelle,
          type: evenement.type,
        });
        this.activites.sort((a, b) => {
          return a.date > b.date ? 1 : -1;
        });
      }
    }
  },
  beforeMount() {
    this.$store.commit('setProcedure', null);

    this.dossier.procedures.sort((a, b) => a.dateCreation < b.dateCreation ? 1 : -1);

    this.dossier.procedures.forEach((procedure) => {
      SocketService
        .getInstance("/procedure/" + procedure.hash)
        .addListener(
          SocketService.EVENT_EVENEMENT_NEW,
          this.pushNewEvenement(procedure),
          this
        );

      api.getEvenements(this.dossier.id, procedure.id).then((evenements) => {
        evenements.forEach((evenement) => {
          this.pushNewEvenement(procedure)(evenement);
        });
        this.loading = false;
      });
    })
  },
};
</script>