<template>
    <b-col md=12>
      <b-row>
        <b-col lg="6" offset="3">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Rechercher..."
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" variant="primary" @click="filter = ''">x</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg=3 class="text-right">
          <b-button-group>
            <b-btn size="sm" variant="primary" @click="showAjouterDossierModal">
              <font-awesome-icon icon="plus" />
            </b-btn>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <br/>
        </b-col>
      </b-row>
      <Loading :size=10  v-if="loading"/>
      <b-row v-else>
        <b-col xs=12>
          <b-table striped hover :items="items" :filter="filter" :fields="fields" ref="dossiers-table" :tbody-tr-class="rowClass" sort-by="date" :sort-desc="true">
            <template #cell(libelle)="data">
              {{ data.item.id }} - {{ data.item.libelle }}
            </template>
            <template #cell(copro)="data">
              <span v-if="data.value">#{{ data.value.id }} {{ data.value.libelle }}</span>
            </template>
            <template #cell(procedures)="data">
              {{ data.value.length }}
            </template>
            <template #cell(dateCreation)="data">
              {{ data.value | moment('DD/MM/YYYY') }}<br/>
              {{ data.value | moment('HH:mm') }}<br/>
              <small>{{ data.value | moment('from') }}</small><br/>
              <small>par {{ data.item.createur.libelle }}</small>
            </template>
            <template #cell(actions)="data">
              <div class="dropdown no-dropdown-carret">
                <b-dropdown variant="link" toggle-class="text-decoration-none" right no-caret>
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                  </template>
                  <b-dropdown-item v-if="data.item.status !== 'fermé'" :to="{name: 'dossier', params: {dossierId: data.item.id}}">
                    Accèder au dossier
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <ModalDossierModifier :procedure="itemDossierSelected" v-bind:show="showedModifierDossierModal" v-on:closed="showedModifierDossierModal=false" v-on:hide="showedModifierDossierModal=false"/>
        </b-col>
      </b-row>
    </b-col>
</template>

<script>
import api from '@/services/api'
import {SocketService} from '@/services/socket'
import Loading from '@/views/components/Loading'
import ModalDossierModifier from '@/views/components/Modal/ModalDossierModifier'

export default {
  name: "TableDossiers",
  components: {
    Loading,
    ModalDossierModifier
  },
  data() {
    return {
      filter: null,
      loading: true,
      fields: [
        {
          key: 'libelle',
          label: 'Dossier',
          sortable: true
        },
        {
          key: 'copro',
          label: 'Copropriété',
          sortable: true
        },
        {
          key: 'procedures',
          label: 'Procédures',
          sortable: true
        },
        {
          key: 'statut',
          label: 'Statut',
          sortable: true
        },
        {
          key: 'dateCreation',
          label: 'Date de création',
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        }
      ],
      items: [],
      itemDossierSelected: null,
      showedModifierDossierModal: false,
    }
  },
  props: [
  ],
  methods: {
    showAjouterDossierModal(){
      this.itemDossierSelected = null;
      this.showedModifierDossierModal = true;
    },
    rowClass(item, type){
        if(item.statut == 'Fermé'){
          return 'table-dark';
        }
        return 'table-success';
    },
    transformToItem(dossier){
      let statut = 'Fermé';
      if(dossier.status){
        statut = 'Ouvert';
      }
      return {
        id: dossier.id,
        copro: dossier.datacopro,
        libelle: dossier.libelle,
        procedures: dossier.procedures,
        dateCreation: dossier.dateCreation,
        createur: dossier.createur,
        statut: statut,
        actions: ''
      };
    },
  },
  beforeMount() {
    this.loading = true;
    api
      .getDossiers()
      .then((dossiers) => {
        dossiers.sort((a, b) => a.dateCreation > b.dateCreation ? -1 : 1);
        dossiers.forEach((dossier) => {
          this.items.push(this.transformToItem(dossier));
        })
        this.loading = false;
      })
  }
};
</script>