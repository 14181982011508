<template>
  <b-modal id="modal-modifier-procedure-contact" 
      :visible="show" 
      @hide="onHide"
      
      @show="showModal"
      @hidden="resetModal"
      :ok-title="contact ? 'Modifier' : 'Ajouter'"
      cancel-title="Annuler"
      :ok-disabled="pushing"
      no-close-on-backdrop
    >
    <template #modal-header>
      <h5 class="modal-title">
        {{ contact ? `Modifier le contact ${contact.prenom} ${contact.nom}` : 'Ajouter un contact à la procédure' }}
      </h5>
    </template>
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Préremplir avec un contact de la copropriété"
        label-for="contact-datacopro-input"
        v-if="dossier.datacopro"
      >
        <v-select 
          v-if="!loadingContactsCopropriete"
          v-model="contactCopropriete" 
          label="libelle"
          :options="contactCoproprieteOptions" 
          id="contactCopropriete-input"
        ></v-select>
        <Loading v-else :size=3 />
        <hr/>
      </b-form-group>

      <b-form-group
        :state="prenomState"
        label="Prénom*"
        label-for="prenom-input"
        invalid-feedback="Le prénom est obligatoire"
      >
        <b-form-input
          id="prenom-input"
          v-model="prenom"
          :state="prenomState"
          placeholder="Prénom"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :state="nomState"
        label="Nom*"
        label-for="nom-input"
        invalid-feedback="Le nom est obligatoire"
      >
        <b-form-input
          id="nom-input"
          v-model="nom"
          :state="nomState"
          placeholder="Nom"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="rolesState"
        label="Rôle"
        label-for="role-select"
        invalid-feedback="Le rôle est obligatoire"
      >
        <v-select multiple v-model="roles" :options="rolesOptions"/>
      </b-form-group>

      <b-form-group
        :state="emailState"
        label="Courriel*"
        label-for="email-input"
        invalid-feedback="Le format de l'email n'est pas respecté"
      >
        <b-form-input
          id="email-input"
          v-model="email"
          :state="emailState"
          placeholder="Courriel"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :state="telephoneState"
        label="Numéro de mobile*"
        label-for="telephone-input"
        invalid-feedback="Le téléphone est obligatoire"
      >
        <b-form-input
          id="telephone-input"
          v-model="telephone"
          :state="telephoneState"
          placeholder="Numéro de mobile"
        ></b-form-input>
      </b-form-group>
    </b-form>
    <Loading v-if="pushing" :size=3 />
    <b-alert variant="danger" :show="failed">
      Une erreur s'est produite lors de la mise à jour
    </b-alert>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/services/api'
import Loading from '@/views/components/Loading'

export default {
  name: 'ModalContactModifier',
  components: {
    Loading
  },
  props: ['procedure', 'contact', 'show'],
  data() {
    return {
      failed: false,
      pushing: false,

      prenom: null,
      prenomState: null,

      nom: null,
      nomState: null,

      roles: [],
      rolesState: null,

      email: null,
      emailState: null,

      telephone: null,
      telephoneState: null,

      loadingContactsCopropriete: false,
      contactCopropriete: null,
      contactCoproprieteOptions: []
    }
  },
  computed: {
    ...mapGetters([
        'dossier'
    ]),
    rolesOptions(){
      const roles = this.procedure.roles;
      if(this.contact){
        this.contact.roles.forEach((role) => {
          if(!roles.find(item => role == item)){
            roles.push(role);
          }
        })
      }
      return roles;
    }
  },
  methods: {
    showModal() {
      this.resetModal();
      if(this.contact){
        this.prenom = this.contact.prenom;
        this.nom = this.contact.nom;
        this.email = this.contact.email;
        this.telephone = this.contact.telephone;
        this.roles = this.contact.roles;
      }
      if(this.dossier.datacopro){
        this.contactCoproprieteOptions = [];
        this.loadingContactsCopropriete = true;
        api.getContactsDatacopro(this.dossier.id)
          .then((contacts) => {
            contacts.forEach((contactLoop) => {
              let libelle = '';
              if(contactLoop.prenom){
                libelle += contactLoop.prenom;
              }
              if(contactLoop.nom){
                libelle += ' ' + contactLoop.nom;
              }
              if(contactLoop.mail){
                libelle += ' [' + contactLoop.mail + ']';
              }

              let mobile = contactLoop.mobile;
              if(contactLoop.tel && contactLoop.tel.indexOf('+336') === 0){
                mobile = contactLoop.tel;
              }
              this.contactCoproprieteOptions.push({
                id: contactLoop.id,
                prenom: contactLoop.prenom,
                nom: contactLoop.nom,
                email: contactLoop.mail,
                mobile: mobile,
                libelle: libelle.trim()
              })
            })
          })
          .catch(() => this.failed = true)
          .finally(() => this.loadingContactsCopropriete = false)
      }
    },
    resetModal() {
      this.prenom = null;
      this.nom = null;
      this.email = null;
      this.telephone = null;
      this.roles = [];

      this.prenomState = null;
      this.nomState = null;
      this.emailState = null;
      this.telephoneState = null;
      this.rolesState = null;

      this.failed = false;
      this.pushing = false;
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity()
      if(!this.prenom || this.prenom.trim() == ''){
        this.prenomState = false;
        valid = false;
      }
      if(!this.nom || this.nom.trim() == ''){
        this.nomState = false;
        valid = false;
      }
      if(!this.telephone || this.telephone.trim() == ''){
        this.telephoneState = false;
        valid = false;
      }
      if(!this.email || this.email.trim() == '' || !this.validEmail(this.email)){
        this.emailState = false;
        valid = false;
      }
      return valid
    },
    onHide(bvModalEvt) {
      if(bvModalEvt.trigger != 'ok'){
        this.$emit('hide');
      }else{
        this.handleOk(bvModalEvt);
      }
    },
    handleOk(bvModalEvt) {
      //pas de fermeture
      bvModalEvt.preventDefault()
      //submit le formulaire
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.pushing = true;
      let promise = null;

      let routeDataEmail = this.$router.resolve({
        name: 'procedure_contact',
        params: {
          hashProcedure: ':hashProcedure',
          hashContact: ':hashContact'
        }
      });
      if(this.contact){
        promise = api.putContact(this.dossier.id, this.procedure.id, this.contact.id, {
          prenom: this.prenom,
          nom: this.nom,
          email: this.email,
          telephone: this.telephone,
          roles: this.roles
        });
      }else{
        promise = api.postContact(this.dossier.id, this.procedure.id, {
          prenom: this.prenom,
          nom: this.nom,
          email: this.email,
          telephone: this.telephone,
          roles: this.roles,
          urlEmail: process.env.VUE_APP_URL+routeDataEmail.href
        });
      }
      promise
        .then(dossier => {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-modifier-procedure-contact');
            this.$emit('hide');
          });
        })
        .catch((error) => {
          this.failed = true;
        })
        .finally(() => {
          this.pushing = false;
        })
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
  watch: {
    contactCopropriete(value){
      if(value){
        this.email = value.email;
        this.prenom = value.prenom;
        this.nom = value.nom;
        this.telephone = value.mobile;
        this.contactCopropriete = null;
      }
    }
  }
}
</script>