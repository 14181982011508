<template>
    <div class="d-flex justify-content-center mb-3">
        <b-spinner :style="spinnerStyle" :label="label"></b-spinner>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        size: {
            type: Number,
            default: 3
        },
        label: {
            type: String,
            default: 'Chargement...'
        }
    },
    computed: {
        spinnerStyle(){
            return {
                width: this.size+'rem', 
                height: this.size+'rem',
                margin: (this.size*10) + 'px'
            }
        }
    }
}
</script>

