<template>
  <b-tab :title="`Activités (${filteredActivites.length})`">
    <form class="px-3 mb-3" v-on:submit.prevent>
      <div class="input-group input-group-round">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="material-icons">filter_list</i>
          </span>
        </div>
        <input
          v-model="searchActivite"
          type="search"
          class="form-control filter-list-input"
          placeholder="Rechercher un vote, un commentaire,..."
          aria-label="Rechercher un vote, un commentaire,..."
        />
      </div>
    </form>
    <ol
      class="list-group list-group-activity flex-column-reverse list-group-flush"
    >
      <li
        class="list-group-item"
        v-for="activite in filteredActivites"
        :key="activite.id"
      >
        <MediaActivite v-bind="activite" />
      </li>
    </ol>
  </b-tab>
</template>

<script>
import { moment } from "vue-moment";
import MediaActivite from '@/views/components/Media/MediaActivite'

export default {
  name: "TabDossierActivites",
  props: ["activites"],
  components: {
    MediaActivite,
  },
  data() {
    return {
      searchActivite: "",
    };
  },
  computed: {
    filteredActivites() {
      return this.activites.filter((activite) => {
        const activiteFieldToSearch = ["description", "auteur", "contexte"];
        let includeActivite = false;
        activiteFieldToSearch.forEach((activiteField) => {
          if (
            activite[activiteField]
              .toLowerCase()
              .includes(this.searchActivite.toLowerCase())
          ) {
            includeActivite = true;
          }
        });
        return includeActivite;
      });
    },
  },
};
</script>